import Cookies from 'universal-cookie';

import { ROUTES } from '@/components/Shared/routes/routes';

import { decrypt, encrypt } from './encrypt';
import { getNavigate } from './helpers/navigate-helper';

const cookies = new Cookies(null, { path: '/' });

export const setUserToken = (data: ITokenData) =>
  cookies.set('hash', encrypt(JSON.stringify(data)));

export const getUserToken = (): ITokenData => {
  try {
    return cookies.get('hash')
      ? JSON.parse(decrypt(cookies.get('hash')!))
      : { accessToken: '', refreshToken: '', emailAllur: '' };
  } catch (err) {
    console.error(err);
    const navigate = getNavigate();
    navigate(ROUTES.login);
    return { accessToken: '', refreshToken: '', emailAllur: '' };
  }
};

export const removeUserToken = () => cookies.remove('hash');

interface ITokenData {
  accessToken: string;
  refreshToken: string;
  emailAllur: string;
}
