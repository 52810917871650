import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  positionsApi,
  rolesActionsApi,
  salePointsApi,
  userApi
} from '@/integrations/index.api';
import { mapUserData } from '@/services/mappers/adminUsersMapper';
import { TPosition } from '@/types/position.type';
import { TRoleAction } from '@/types/roleAction.type';
import { TSalePoint } from '@/types/salePoint.type';
import { TUserDto, TUserResponse, TUserUpdateDto } from '@/types/user.type';

type TUserSliceProps = {
  users: TUserDto[];
  positions: TPosition[];
  sale_points: TSalePoint[];
  roles_actions: TRoleAction[];
  total: number | undefined;
  isLoading: boolean;
  user: TUserResponse | null;
  position: TPosition | null;
  role_action: TRoleAction | null;
};

const initialState: TUserSliceProps = {
  users: [],
  positions: [],
  sale_points: [],
  roles_actions: [],
  total: 0,
  isLoading: false,
  user: null,
  position: null,
  role_action: null
};

export const getAllPositions = createAsyncThunk(
  'admin/positions/getAllPositions',
  async (data: {
    limit?: number;
    page?: number;
    searchTerm?: string;
    isActive?: boolean;
  }) => {
    try {
      const res = await positionsApi.getAdminAllPositions(
        data.limit,
        data.page,
        data.searchTerm,
        data.isActive
      );
      return {
        total: res.total,
        positions: res.data
      };
    } catch {
      throw new Error('Failed to fetch users');
    }
  }
);

export const createPosition = createAsyncThunk(
  'admin/user/createPosition',
  async (data: TPosition) => {
    await positionsApi.createPosition(data);
  }
);
export const updatePosition = createAsyncThunk(
  'admin/user/updatePosition',
  async (data: TPosition) => {
    await positionsApi.updatePosition(data);
  }
);

export const getPositionById = createAsyncThunk(
  'admin/getPositionById',
  async (userId: string) => {
    const res = await positionsApi.getPositionById(userId);
    return res.data;
  }
);

export const getSalePoints = createAsyncThunk(
  'admin/users/getSalePoints',
  async () => {
    const res = await salePointsApi.getAdminSalePoints();
    return res;
  }
);

export const getRolesActions = createAsyncThunk(
  'admin/role_actions/getRolesActions',
  async (data: { limit?: number; page?: number; searchTerm?: string }) => {
    try {
      const res = await rolesActionsApi.getAllRolesActions(
        data.limit,
        data.page,
        data.searchTerm
      );
      return {
        total: res.total,
        roles_actions: res.data
      };
    } catch {
      throw new Error('Failed to fetch users');
    }
  }
);

export const createRolesAction = createAsyncThunk(
  '/admin/users/creteRolesActions',
  async (data: TRoleAction) => {
    await rolesActionsApi.createRolesAction(data);
  }
);

export const updateRolesAction = createAsyncThunk(
  'admin/users/updateRolesAction',
  async (data: TRoleAction) => {
    await rolesActionsApi.updateRolesAction(data);
  }
);

export const getRolesById = createAsyncThunk(
  'admin/getRolesById',
  async (userId: string) => {
    const res = await rolesActionsApi.getRolesById(userId);
    return res.data;
  }
);

export const getUsersById = createAsyncThunk(
  'admin/getUserById',
  async (userId: string) => {
    const res = await userApi.getAdminUserById(userId);
    return res.data;
  }
);

export const getAdminAllUsers = createAsyncThunk(
  'admin/getAdminAllUsers',
  async (data: {
    limit?: number;
    offset?: number;
    searchTerm?: string;
    isActive?: boolean;
    position?: string;
  }) => {
    try {
      const res = await userApi.getAdminAllUsers(
        data.limit,
        data.offset,
        data.searchTerm,
        data.isActive,
        data.position
      );
      return {
        total: res.total,
        users: res.data
      };
    } catch {
      throw new Error('Failed to fetch users');
    }
  }
);

export const createUser = createAsyncThunk(
  'admin/users/create',
  async (data: TUserDto) => {
    const res = await userApi.createAdminUser(data);
    return res;
  }
);

export const updateUser = createAsyncThunk(
  'admin/users/update',
  async (data: TUserDto) => {
    const updatedData: TUserUpdateDto = mapUserData(data);
    return await userApi.updateAdminUser(data.uuid || '', updatedData);
  }
);

export const adminUsersSlice = createSlice({
  name: 'admin/users',
  initialState,
  reducers: {
    resetUserState(state) {
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminAllUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdminAllUsers.fulfilled, (state, action) => {
        state.users = action.payload.users;
        state.total = action.payload.total;
        state.isLoading = false;
      })
      .addCase(getSalePoints.fulfilled, (state, action) => {
        state.sale_points = action.payload;
      })
      .addCase(getRolesActions.fulfilled, (state, action) => {
        state.roles_actions = action.payload.roles_actions;
        state.isLoading = false;
        state.total = action.payload.total;
      })
      .addCase(getAllPositions.fulfilled, (state, action) => {
        state.positions = action.payload.positions;
        state.isLoading = false;
        state.total = action.payload.total;
      })
      .addCase(getUsersById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(getUsersById.pending, (state) => {
        state.isLoading = true;
        state.user = null;
      })
      .addCase(getUsersById.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getPositionById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.position = action.payload;
      })
      .addCase(getPositionById.pending, (state) => {
        state.isLoading = true;
        state.position = null;
      })
      .addCase(getPositionById.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getRolesById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.role_action = action.payload;
      })
      .addCase(getRolesById.pending, (state) => {
        state.isLoading = true;
        state.role_action = null;
      })
      .addCase(getRolesById.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const { resetUserState } = adminUsersSlice.actions;
