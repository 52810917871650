import { TClientData, TClientServerResponse } from '@/types/clients';
import { TClientStoreFormat } from '@/types/store';
import { clientCalculationsMapper } from '@/services/mappers/clientCalculationsMapper';
import { formatPhoneNumber } from '@/services/formatter';
import { clientsRequestsMapper } from '@/services/mappers/clientsRequestsMapper';
import { getLastRequestDate } from '@/services';

export const clientMapperAdmin = (data: TClientServerResponse): TClientData => {
  return {
    uuid: data.uuid || '',
    client_iin: data.client_iin || '',
    first_name: data.first_name || '',
    middle_name: data.middle_name || '',
    last_name: data.last_name || '',
    phone_number: data.phone_number || '',
    birth_date:
      data.birth_date instanceof Date
        ? data.birth_date.toISOString()
        : data.birth_date || '',
    salary: data.salary || 0,
    additional_income: data.additional_income || 0,
    marital_status_uuid: data.marital_status_uuid || '',
    job_title: data.job_title || '',
    child_count: data.child_count || 0,
    social_status_uuid: data.social_status_uuid || '',
    organization_type_uuid: data.organization_type_uuid || '',
    company_activity_type_uuid: data.company_activity_type_uuid || null,
    title_company: data.title_company || '',
    Client_Add_Contacts: data.Client_Add_Contacts || null,
    work_tel_number: data.work_tel_number || '',
    work_address: data.work_address || '',
    gender_uuid: data.gender_uuid || ''
  };
};

export const clientResponseMapper = (
  serverResponse: TClientServerResponse[]
): TClientStoreFormat[] => {
  return serverResponse.map((response) => {
    const lastRequestCreationDate = getLastRequestDate(response.Requests);
    const clientsCalculationsMapperResults = clientCalculationsMapper(
      response.Client_Calculations
    );
    const formattedData: TClientStoreFormat = {
      id: response?.uuid || '',
      clientIin: response?.client_iin || '',
      clientBirthDate: response?.birth_date?.toString() || '',
      clientFirstName: response?.first_name || '',
      clientMiddleName: response?.middle_name || '',
      clientLastName: response?.last_name || '',
      clientPhoneNum: formatPhoneNumber(response?.phone_number || ''),
      clientSex: response?.gender?.name || '',
      clientCreationDate: response?.create_date?.toString() || '',
      clientRequests: clientsRequestsMapper(response.Requests, {
        fullName: `${response?.last_name || ''} ${response?.first_name || ''} ${
          response?.middle_name || ''
        }`,
        iin: response?.client_iin || '',
        phoneNum: response?.phone_number || ''
      }),
      lastRequestCreationDate: lastRequestCreationDate,
      clientCalculations: clientsCalculationsMapperResults
    };
    return formattedData;
  });
};
