import { TUserDto, TUserUpdateDto } from '@/types/user.type';

export const mapUserData = (item: TUserDto): TUserUpdateDto => {
  return {
    update_type: item.update_type,
    login: item.login || '',
    email: item.email || '',
    lastName: item.last_name || '',
    firstName: item.first_name || '',
    middleName: item.middle_name || '',
    phoneNumber: item.phone_number,
    birthDate: item.birth_date,
    positionId: item.position.uuid,
    salePointId: item.sale_point?.uuid,
    isActive: item.is_active,
    iin: item.user_iin,
    workDateStart: item.work_date_start,
    dealerIds: item.dealerIds,
    activity_type: item.activity_type,
    freezing_start_date: item.freezing_start_date,
    freezing_end_date: item.freezing_end_date || null
  };
};
