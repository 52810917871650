import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { modulesApi } from '@/integrations/index.api';
import {
  TAdminModule,
  TModuleWithRoles,
  TUpdateAdminModule
} from '@/types/modulesAdmin';

type TModuleSliceProps = {
  modules: TAdminModule[];
  total?: number;
  isLoading: boolean;
  module: TModuleWithRoles | null;
};

const initialState: TModuleSliceProps = {
  modules: [],
  total: 0,
  isLoading: false,
  module: null
};

export const getModuleById = createAsyncThunk(
  'admin/getUserById',
  async (moduleId: string) => {
    const res = await modulesApi.getAdminModuleById(moduleId);
    return res.data;
  }
);

export const getModulesAll = createAsyncThunk(
  'admin/getAdminAll',
  async (data: { limit?: number; offset?: number; searchTerm?: string }) => {
    try {
      const res = await modulesApi.getAdminAllModules(
        data.limit,
        data.offset,
        data.searchTerm
      );
      return {
        total: res.total,
        modules: res.data
      };
    } catch {
      throw new Error('Failed to fetch modal');
    }
  }
);

export const createModules = createAsyncThunk(
  'admin/module/create',
  async (data: TUpdateAdminModule) => {
    const res = await modulesApi.createAdminModules(data);
    return res;
  }
);
export const updateModule = createAsyncThunk(
  'admin/module/update',
  async (data: TUpdateAdminModule) => {
    return await modulesApi.updateAdminModules(data.uuid || '', data);
  }
);

const adminModulesSlice = createSlice({
  name: 'admin/modules',
  initialState,
  reducers: {
    resetModuleState(state) {
      state.module = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getModulesAll.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getModulesAll.fulfilled, (state, action) => {
        state.modules = action.payload.modules;
        state.total = action.payload.total;
        state.isLoading = false;
      })
      .addCase(getModuleById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.module = action.payload;
      })
      .addCase(getModuleById.pending, (state) => {
        state.isLoading = true;
        state.module = null;
      })
      .addCase(getModuleById.rejected, (state) => {
        state.isLoading = false;
      });
  }
});
export const { resetModuleState } = adminModulesSlice.actions;
export default adminModulesSlice;
