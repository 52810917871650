import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { WIDTH_FOR_OPENED_NAVBAR } from '@/constants';
import { userInfoMapper } from '@/services/mappers/userInfoMapper';

import { apiConnector } from '../integrations/api.connector';
import { TUserInfoStore } from '../types/store';
import { DecodedJWTToken, TModule, TUserResponse } from '../types/user';

export const getUserAccount = createAsyncThunk(
  'userInfo/getUserAccount',
  async (): Promise<any> => {
    try {
      return await apiConnector.getUserAccount();
    } catch (e) {
      console.error(e);
    }
  }
);

export const getUserMenu = createAsyncThunk(
  'userInfo/getUserMenu',
  async (dealerId: string): Promise<any> => {
    try {
      return await apiConnector.getUserMenu(dealerId);
    } catch (e) {
      console.error(e);
    }
  }
);

const initialState: TUserInfoStore = {
  jwtDecodedInfo: null,
  userInfo: null,
  dealer: null,
  menu: [],
  isSidebarExpanded: window.innerWidth > WIDTH_FOR_OPENED_NAVBAR
};

export const userInfoSlice = createSlice({
  name: 'userInfoSlice',
  initialState,
  reducers: {
    setDealer: (
      state,
      action: PayloadAction<{ value: string; label: string; code: number }>
    ) => {
      state.dealer = action.payload;
    },
    setJwtDecodedInfo: (state, action: PayloadAction<DecodedJWTToken>) => {
      state.jwtDecodedInfo = action.payload;
    },
    setSidebarExpanded: (state, action: PayloadAction<boolean>) => {
      state.isSidebarExpanded = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(
        getUserAccount.fulfilled,
        (state, action: PayloadAction<TUserResponse>) => {
          state.userInfo = userInfoMapper(action.payload);
        }
      )
      .addCase(
        getUserMenu.fulfilled,
        (state, action: PayloadAction<TModule[]>) => {
          state.menu = action.payload
            ? action.payload.sort((a, b) => a.position - b.position)
            : [];
        }
      );
  }
});

export const { setDealer, setJwtDecodedInfo, setSidebarExpanded } =
  userInfoSlice.actions;
