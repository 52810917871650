import './upload-file.scss';

import { notification } from 'antd';
import { forwardRef } from 'react';

import AttachedCircleIcon from '@/media/icons/attach-circle.svg?react';
import CrossIcon from '@/media/icons/cross.svg?react';
import PlusIcon from '@/media/icons/plus.svg?react';
import { convertFileToBase64 } from '@/services/helpers/files';

import Input, { IInputProps } from '../input';

interface IProps extends Omit<IInputProps, 'onChange' | 'value'> {
  value?: FileInterface | FileInterface[];
  onChange?: (file: FileInterface | FileInterface[]) => void;
  maxFilesCount?: number;
}

const UploadFile: React.FC<IProps> = forwardRef<HTMLInputElement, IProps>(
  ({ value, onChange, maxFilesCount = 10, ...rest }, forwardedRef) => {
    const processFile = async (file: File) => {
      try {
        const base64 = (await convertFileToBase64(file))?.[1];
        return { name: file.name, base64, file };
      } catch (error: unknown) {
        console.error(error);
        notification.error({
          message: `Не получилось загрузить файл ${file.name}`
        });
        return { name: undefined, base64: undefined, file: undefined };
      }
    };

    const handleFileChanged = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const files = Array.from(event.target.files || []);

      if (rest.multiple) {
        const correctValue =
          value === undefined || !Array.isArray(value) ? [] : value;
        const uniqueFiles = files.filter(
          // Фильтруем уже добавленные файлы
          (file) => !correctValue.some((f) => f.name === file.name)
        );
        try {
          const fileDataArray = (
            await Promise.all(uniqueFiles.map(processFile))
          ).filter((item) => item.name);
          // Добавляем новые файлы к имеющимся
          const result = [...correctValue, ...fileDataArray].slice(
            0,
            maxFilesCount
          );
          onChange?.(result);
        } catch (error: unknown) {
          console.error(error);
          // Возвращаем исходные файлы
          onChange?.(correctValue);
        }
        return;
      }

      const file = files[0];
      const fileData = await processFile(file);
      onChange?.(fileData);
    };

    if (rest.multiple || Array.isArray(value)) {
      const correctValue =
        value === undefined || !Array.isArray(value) ? [] : value;
      const handleRemoveFile = (index: number) => {
        const files = correctValue.filter((_, i) => i !== index);
        onChange?.(files);
      };

      return (
        <>
          {correctValue?.length < maxFilesCount && !rest.isFrozen && (
            <label className="upload-file">
              <input
                {...rest}
                ref={forwardedRef}
                type="file"
                onChange={handleFileChanged}
                className="upload-file__hidden-field"
              />
              <div className="upload-file__add-button">
                <>
                  Добавить документ
                  <PlusIcon />
                </>
              </div>
            </label>
          )}

          {correctValue?.length > 0 && (
            <div className="upload-file__list">
              {correctValue.map((item, index) => (
                <div key={item.name} className="upload-file__list-item">
                  <p className="upload-file__item-name">{item.name}</p>
                  {!rest.isFrozen && (
                    <button
                      type="button"
                      onClick={() => {
                        handleRemoveFile(index);
                      }}
                      className="upload-file__cross"
                    >
                      <CrossIcon />
                    </button>
                  )}
                </div>
              ))}
            </div>
          )}
        </>
      );
    }

    return (
      <div className="upload-file">
        <Input
          {...rest}
          ref={forwardedRef}
          type="file"
          onChange={handleFileChanged}
          fileName={value?.name}
          endContent={
            <div className="upload-file__end-content">
              {rest.endContent || <AttachedCircleIcon />}
            </div>
          }
        />
      </div>
    );
  }
);

UploadFile.displayName = 'UploadFile';

export default UploadFile;

interface FileInterface {
  name?: string;
  base64?: string;
  file?: File;
}
