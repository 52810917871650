import { AxiosInstance } from 'axios';

import { TClientData, TClientServerResponse } from '@/types/clients';

export class ClientsApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async getAdminAllClients(
    limit?: number,
    page?: number,
    searchTerm?: string
  ): Promise<{
    total: number;
    data: TClientServerResponse[];
  }> {
    let path = `admin/clients/getClients?`;

    if (limit) {
      path = path + `limit=${limit}`;
    }
    if (page) {
      path = path + `&page=${page}`;
    }
    if (searchTerm) {
      path = path + `&searchTerm=${searchTerm}`;
    }
    const res = await this.api.get(path);
    return res.data;
  }

  public async getAdminClientById(id: string): Promise<TClientServerResponse> {
    const res = await this.api.get(`admin/clients/${id}`);
    return res.data;
  }

  public async updateClientById(clientId: TClientData) {
    const res = await this.api.patch(
      `/admin/clients/${clientId.uuid}`,
      clientId
    );
    return res.data;
  }
}
