import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { modelsApi } from '@/integrations/index.api';
import {
  TBrand,
  TCreateBrand,
  TCreateEquipment,
  TEquipment,
  TModel,
  TModelCreate
} from '@/types/model.type';

type TModelsSlice = {
  isLoading: boolean;
  brands: TBrand[];
  models: TModel[];
  equipments: TEquipment[];
  total: number;
  brand: TBrand | null;
  model: TModel | null;
  equipment: TEquipment | null;
};

const initialState: TModelsSlice = {
  isLoading: false,
  brands: [],
  models: [],
  equipments: [],
  total: 0,
  brand: null,
  model: null,
  equipment: null
};

export const getBrands = createAsyncThunk(
  'admin/brands',
  async ({
    limit,
    page,
    searchTerm
  }: {
    limit?: number;
    page?: number;
    searchTerm?: string;
  }) => {
    try {
      const res = await modelsApi.getBrands(limit, page, searchTerm);

      return res;
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getModels = createAsyncThunk(
  'admin/models',
  async ({
    limit,
    page,
    searchTerm,
    brand,
    isCommercial
  }: {
    limit?: number;
    page?: number;
    searchTerm?: string;
    brand?: string;
    isCommercial?: boolean;
  }) => {
    try {
      const res = await modelsApi.getModels(
        limit,
        page,
        searchTerm,
        brand,
        isCommercial
      );

      return res;
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getEquipments = createAsyncThunk(
  'admin/equipments',
  async ({
    limit,
    page,
    searchTerm,
    brand,
    model
  }: {
    limit?: number;
    page?: number;
    searchTerm?: string;
    brand?: string;
    model?: string;
  }) => {
    try {
      const res = await modelsApi.getEquipments(
        limit,
        page,
        searchTerm,
        brand,
        model
      );
      return res;
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const createModels = createAsyncThunk(
  'admin/module/create',
  async (data: TModelCreate, { rejectWithValue }) => {
    try {
      const res = await modelsApi.createModels(data);
      return res;
    } catch (error: any) {
      return rejectWithValue(error?.response.data.message);
    }
  }
);

export const updateModels = createAsyncThunk(
  'admin/models/update',
  async ({ request_id, data }: { request_id: string; data: TModelCreate }) => {
    return await modelsApi.updateModels(request_id, data);
  }
);

export const getModelsById = createAsyncThunk(
  'admin/modelsId',
  async (modelId: string) => {
    const res = await modelsApi.getAdminModelId(modelId);
    return res.data;
  }
);

export const updateEquipments = createAsyncThunk(
  'admin/equipments/update',
  async ({
    request_id,
    data
  }: {
    request_id: string;
    data: TCreateEquipment;
  }) => {
    return await modelsApi.updateEquipments(request_id, data);
  }
);

export const getEquipmentById = createAsyncThunk(
  'admin/equipmentId',
  async (equipmentId: string) => {
    const res = await modelsApi.getAdminEquipmentId(equipmentId);
    return res.data;
  }
);

export const createBrands = createAsyncThunk(
  'admin/brands/create',
  async (data: TCreateBrand, { rejectWithValue }) => {
    try {
      const res = await modelsApi.createBrands(data);
      return res;
    } catch (error: any) {
      return rejectWithValue(error?.response.data.message);
    }
  }
);

export const updateBrands = createAsyncThunk(
  'admin/brands/update',
  async (data: TCreateBrand) => {
    return await modelsApi.updateBrands(data.uuid || '', data);
  }
);

export const getBrandsById = createAsyncThunk(
  'admin/brandsId',
  async (brandId: string) => {
    const res = await modelsApi.getAdminBrandId(brandId);
    return res.data;
  }
);

export const createEquipments = createAsyncThunk(
  'admin/equipments/create',
  async (data: TCreateEquipment, { rejectWithValue }) => {
    try {
      const res = await modelsApi.createEquipments(data);
      return res;
    } catch (error: any) {
      return rejectWithValue(error?.response.data.message);
    }
  }
);

export const adminModelsSlice = createSlice({
  name: 'admin/models',
  initialState: initialState,
  reducers: {
    resetAll: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBrands.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBrands.fulfilled, (state, action) => {
        state.brands = action.payload.data;
        state.total = action.payload.total;
        state.isLoading = false;
      })
      .addCase(getBrands.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getModels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getModels.fulfilled, (state, action) => {
        state.models = action.payload.data;
        state.total = action.payload.total;
        state.isLoading = false;
      })
      .addCase(getModels.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getEquipments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEquipments.fulfilled, (state, action) => {
        state.equipments = action.payload.data;
        state.total = action.payload.total;
        state.isLoading = false;
      })
      .addCase(getEquipments.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getBrandsById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.brand = action.payload;
      })
      .addCase(getBrandsById.pending, (state) => {
        state.isLoading = true;
        state.brand = null;
      })
      .addCase(getBrandsById.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getModelsById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.model = action.payload;
      })
      .addCase(getModelsById.pending, (state) => {
        state.isLoading = true;
        state.model = null;
      })
      .addCase(getModelsById.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getEquipmentById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.equipment = action.payload;
      })
      .addCase(getEquipmentById.pending, (state) => {
        state.isLoading = true;
        state.equipment = null;
      })
      .addCase(getEquipmentById.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const { resetAll } = adminModelsSlice.actions;
