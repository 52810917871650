import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { addProductsDictionaryMapper } from '@/services/mappers/addProductsDictionaryMapper';
import { stockbondsProductsDictionaryMapper } from '@/services/mappers/stockbondsProductsDictionaryMapper';

import { DictTypes, DictTypesKeys } from '../constants';
import { apiConnector } from '../integrations/api.connector';
import {
  DictContentInterface,
  TAddProductDictionaryResponse,
  TDict,
  TDictStore,
  TProductInsuranceServerResponse
} from '../types/store';

export const getProductsDictionary = createAsyncThunk(
  'dict/getProductsDictionary',
  async (): Promise<TAddProductDictionaryResponse[]> => {
    try {
      return await apiConnector.getProductsDictionary();
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getProductInsurance = createAsyncThunk(
  'dict/getProductInsurance',
  async (): Promise<TProductInsuranceServerResponse[]> => {
    try {
      return await apiConnector.getProductInsurance();
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getPromosByModel = createAsyncThunk(
  'dict/getPromosByModel',
  async (modelId: string) => {
    try {
      return await apiConnector.getPromosByModel(modelId);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getOptimizedDictionaries = createAsyncThunk(
  'dict/getOptimizedDictionaries',
  async (): Promise<Record<DictTypesKeys, TDict>> => {
    try {
      const dictTypes = Object.values(DictTypes).filter(
        (item) => item !== DictTypes.Cities
      );
      const dicts = await apiConnector.getOptimizedDictionaries(dictTypes);
      const cities = await apiConnector.getCitiesForFilter();
      const result = dicts.reduce(
        (acc: Record<DictTypesKeys, TDict>, dict: any) => {
          acc[dict.name as DictTypes] = {
            dictType: dict.name,
            content: dict.content
          };
          return acc;
        },
        {} as Record<DictTypesKeys, TDict>
      );
      return {
        ...result,
        [DictTypes.Cities]: {
          dictType: DictTypes.Cities,
          content: cities as DictContentInterface[]
        }
      };
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getActiveDealers = createAsyncThunk(
  'dict/getActiveDealers',
  async (): Promise<any> => {
    try {
      const data = await apiConnector.getDealersAll();
      return data.filter((dealer) => dealer?.is_active);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

const initialState: TDictStore = {
  dicts: null,
  dictStatus: 'idle',
  additionalProductsDict: null,
  paymentTypes: null,
  stockBoundsProductsDict: null,
  otherUsers: null,
  isLoading: false,
  addProductsInsurances: null,
  statusForFilterOptions: null,
  regionOption: null,
  cityOption: null,
  cityForFilterOption: null,
  villageOption: null
};

export const dictSlice = createSlice({
  name: 'dict',
  initialState: initialState,
  reducers: {
    setProductDictsToInitial: (state) => {
      state.stockBoundsProductsDict = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOptimizedDictionaries.pending, (state) => {
        state.dictStatus = 'pending';
        state.isLoading = true;
      })
      .addCase(getOptimizedDictionaries.rejected, (state) => {
        state.dictStatus = 'rejected';
        state.isLoading = false;
      })
      .addCase(getOptimizedDictionaries.fulfilled, (state, action) => {
        state.dictStatus = 'fulfilled';
        state.isLoading = false;
        state.dicts = action.payload;
        const statusesResults = action.payload[DictTypes.Statuses];
        const paymentTypesResults = action.payload[DictTypes.RepaymentTypes];
        if (paymentTypesResults) {
          state.paymentTypes = paymentTypesResults.content.map((item) => ({
            value: item.uuid,
            label: item.name,
            code: item.code
          }));
        }
        if (statusesResults) {
          state.statusForFilterOptions = statusesResults.content.map(
            (item) => ({
              value: item.uuid,
              label: item.name,
              code: item.code
            })
          );
        }
      })

      .addCase(getProductsDictionary.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductsDictionary.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(
        getProductsDictionary.fulfilled,
        (state, action: PayloadAction<TAddProductDictionaryResponse[]>) => {
          state.additionalProductsDict = addProductsDictionaryMapper(
            action.payload
          ).filter((product) => product.productType === 'Доп. продукт');
        }
      )
      .addCase(getPromosByModel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPromosByModel.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getPromosByModel.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stockBoundsProductsDict = stockbondsProductsDictionaryMapper(
          action.payload
        ).filter((item) => item.uuid);
      })

      .addCase(getProductInsurance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductInsurance.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(
        getProductInsurance.fulfilled,
        (state, action: PayloadAction<TProductInsuranceServerResponse[]>) => {
          state.isLoading = false;
          state.addProductsInsurances = action.payload;
        }
      );
  }
});

export const { setProductDictsToInitial } = dictSlice.actions;
