import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiConnector } from '@/integrations/api.connector';
import { formatBackBankOfferResponse } from '@/services/formatter/formatBackBankOfferResponse';
import { bankResponsesMapper } from '@/services/mappers/bankResponsesMapper';
import { ChangeBankStatusInterface, TBankOffers } from '@/types/offers';
import { TOfferStepStore } from '@/types/store';

export const getResponseByRequestId = createAsyncThunk(
  'offerStep/getResponseByRequestId',
  async (reqId: string): Promise<any> => {
    const data = await apiConnector.getResponseByRequestId(reqId);

    const halykOffer = data.find(
      (response: any) => response.bank.name === 'halyk'
    );
    if (
      halykOffer &&
      (halykOffer.status.name === 'Одобрено' ||
        halykOffer.status.name === 'Профинансировано' ||
        halykOffer.status.name === 'Ожидание финансирования')
    ) {
      const responseApprovedData = halykOffer.response_approved_body
        ? JSON.parse(halykOffer.response_approved_body)
        : null;
      if (responseApprovedData) {
        const halykOfferindex = data.findIndex(
          (response: any) => response.bank?.name === 'halyk'
        );

        if (halykOfferindex !== -1) {
          const responseApprovedData = JSON.parse(
            halykOffer?.response_approved_body || '{}'
          );

          const loanAmount =
            responseApprovedData?.additional?.[0]?.loan_amount || '';
          const downPayment =
            Number(halykOffer?.vehicle_cost || 0) -
            Number(responseApprovedData?.additional?.[0]?.down_payment || 0);
          const monthlyPayment =
            responseApprovedData?.additional?.[0]?.monthly_payment || '';
          const rate =
            responseApprovedData?.additional?.[0]?.interest_rate || '';

          data[halykOfferindex] = {
            ...data[halykOfferindex],
            loan_amount: loanAmount,
            down_payment: downPayment,
            monthly_payment: monthlyPayment,
            rate: rate,
            request: {
              ...(data[halykOfferindex]?.request || {})
            }
          };
        }
      }
    }
    const eubankOffer = data.find((response: any) =>
      response.bank.name.includes('eu_bank')
    );
    if (
      (eubankOffer && eubankOffer?.status?.code === 'approved') ||
      eubankOffer?.status?.code === 'financed_pledge_missing' ||
      eubankOffer?.status?.code ===
        'financed_waiting_for_modification_before_pledge' ||
      eubankOffer?.status?.code === 'waiting_for_upload_files' ||
      eubankOffer?.status?.code === 'waiting_of_financing' ||
      eubankOffer?.status?.code === 'financed' ||
      eubankOffer?.status?.code === 'accepted_alternative'
    ) {
      const responseApprovedData = JSON.parse(
        eubankOffer.response_approved_body
      );
      const eubankOfferIndex = data.findIndex((response: any) =>
        response.bank.name.includes('eu_bank')
      );
      data[eubankOfferIndex] = {
        ...data[eubankOfferIndex],
        monthly_payment:
          responseApprovedData?.status === 'ALTERNATIVE'
            ? responseApprovedData?.alternative?.monthlyPayment1
            : responseApprovedData?.calculation?.monthlyPayment1 || '',

        rate:
          Number(responseApprovedData?.calculation?.yearlyRate).toFixed(2) ||
          '',
        request: {
          ...(data[eubankOfferIndex]?.request || '')
        }
      };
    }
    const shinHanOffer = data.find(
      (response: any) => response.bank.name === 'shinhan'
    );

    if (
      (shinHanOffer && shinHanOffer?.status?.name === 'Одобрено') ||
      shinHanOffer?.status?.name === 'Ожидание финансирования' ||
      shinHanOffer?.status?.name === 'Работа в Мобильном ПО'
    ) {
      const shihanOfferIndex = data.findIndex(
        (response: any) => response.bank.name === 'shinhan'
      );
      const responseApprovedData = JSON.parse(
        shinHanOffer.response_approved_body
      );
      data[shihanOfferIndex] = {
        ...data[shihanOfferIndex],
        rate: responseApprovedData?.interest_rate || '',
        // loan_amount: responseApprovedData?.loan_amount || '',
        monthly_payment: responseApprovedData?.monthly_payment || ''
        // down_payment: responseApprovedData?.downpayment || '',
        // loan_period: responseApprovedData?.duration || ''
      };
    }
    return data;
  }
);

export const getEblankStatus = createAsyncThunk(
  'offerStep/getEblankStatus',
  async (data: { requestId: string; responseExtUuid: string }) => {
    try {
      return await apiConnector.getEblankStatus(data);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const getForteStatus = createAsyncThunk(
  'offerStep/getForteStatus',
  async (extUuid: string) => {
    try {
      return await apiConnector.getForteStatus(extUuid);

      // return res;
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const acceptOffer = createAsyncThunk(
  'offerStep/acceptOffer',
  async (data: { responseId: string; offerId?: string }) => {
    return await apiConnector.acceptOffer(data.responseId, data.offerId);
  }
);

export const changeOfferStatus = createAsyncThunk(
  'offerStep/changeOfferStatus',
  async (data: ChangeBankStatusInterface): Promise<any> => {
    try {
      return await apiConnector.changeOfferStatus(data);
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

const initialState: TOfferStepStore = {
  banksOffers: [],
  offer: null,
  isLoading: false,
  reqSuccessIndicator: null,
  offerUuid: null
};

export const offerStepSlice = createSlice({
  name: 'offerStep',
  initialState,
  reducers: {
    setBankOffers: (state, action: PayloadAction<TBankOffers[]>) => {
      state.banksOffers = action.payload;
    },
    setOfferUuid: (state, action: PayloadAction<string>) => {
      state.offerUuid = action.payload;
    },
    resetOfferUuid: (state) => {
      state.offerUuid = null;
    },
    resetReqSuccessIndicator: (state) => {
      state.reqSuccessIndicator = null;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getResponseByRequestId.rejected, (state) => {
        state.reqSuccessIndicator = true;
      })
      .addCase(getResponseByRequestId.fulfilled, (state, action) => {
        state.banksOffers = bankResponsesMapper(action.payload);
        state.reqSuccessIndicator = true;
      })

      .addCase(changeOfferStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changeOfferStatus.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(changeOfferStatus.fulfilled, (state) => {
        state.isLoading = false;
      })

      .addCase(getEblankStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEblankStatus.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getEblankStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        const bankOffersHandler = [...state.banksOffers];
        const eubankOfferIndex = bankOffersHandler.findIndex((bank) =>
          bank.bank.name.includes('eu_bank')
        );
        if (
          action?.payload?.status?.name === 'Одобрено' ||
          action?.payload?.status?.name === 'Ожидание загрузки файлов' ||
          action?.payload?.status?.name === 'Доработка по аресту' ||
          action?.payload?.status?.name === 'Ожидается загрузка Ареста Авто'
        ) {
          const responseApprovedData = JSON.parse(
            action.payload.response_approved_body
          );
          action.payload = {
            ...action.payload,
            monthly_payment:
              responseApprovedData?.calculation?.monthlyPayment || '',
            rate: responseApprovedData?.calculation?.eir || ''
          };
        }
        const mappedEblankOffer = formatBackBankOfferResponse(action.payload);
        bankOffersHandler[eubankOfferIndex] = { ...mappedEblankOffer };
        state.banksOffers = bankOffersHandler;
      })
      .addCase(getForteStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getForteStatus.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getForteStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        const bankOffersHandler = [...state.banksOffers];
        const forteOfferIndex = bankOffersHandler.findIndex(
          (bank) => bank.bank.name === 'forte'
        );
        const mappedForteOffer = formatBackBankOfferResponse(action.payload);
        bankOffersHandler[forteOfferIndex] = { ...mappedForteOffer };
        state.banksOffers = bankOffersHandler;
      });
  }
});

export const {
  setBankOffers,
  resetReqSuccessIndicator,
  setOfferUuid,
  resetOfferUuid
} = offerStepSlice.actions;
