import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { dealersApi } from '@/integrations/index.api';
import { TDealer, TDealerDto, TDealerResponse } from '@/types/dealer.type';

type TDealersSlice = {
  isLoading: boolean;
  total: number;
  dealers: TDealer[];
  dealer: TDealerResponse | null;
};

const initialState: TDealersSlice = {
  isLoading: false,
  total: 0,
  dealers: [],
  dealer: null
};

export const getAllDealers = createAsyncThunk(
  'admin/dealers/getAll',
  async ({
    limit,
    page,
    searchTerm,
    city,
    isActive,
    hasCommercial
  }: {
    limit?: number;
    page?: number;
    searchTerm?: string;
    city?: string;
    isActive?: boolean;
    hasCommercial?: boolean;
  }) => {
    const res = await dealersApi.getAdminAllDealers(
      limit,
      page,
      searchTerm,
      city,
      isActive,
      hasCommercial
    );
    return res;
  }
);

export const getDealerById = createAsyncThunk(
  'admin/getDealerById',
  async (dealerId: string) => {
    const res = await dealersApi.getAdminById(dealerId);
    return res.data;
  }
);

export const createDealer = createAsyncThunk(
  'admin/dealers/create',
  async (dealer: TDealerDto) => {
    return await dealersApi.createAdminDealer(dealer);
  }
);

export const updateDealer = createAsyncThunk(
  'admin/dealers/update',
  async (dealer: TDealer, { rejectWithValue }) => {
    try {
      const res = await dealersApi.updateAdminDealer(dealer);
      return res;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const adminDealersSlice = createSlice({
  name: 'admin/dealers',
  initialState: initialState,
  reducers: {
    resetDealerState(state) {
      state.dealer = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDealers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllDealers.fulfilled, (state, action) => {
        state.dealers = action.payload.dealers;
        state.isLoading = false;
        state.total = action.payload.total;
      })
      .addCase(getDealerById.fulfilled, (state, action) => {
        state.dealer = action.payload;
        state.total = action.payload.total;
        state.isLoading = false;
      })
      .addCase(getDealerById.pending, (state) => {
        state.isLoading = true;
        state.dealer = null;
      })
      .addCase(getDealerById.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const { resetDealerState } = adminDealersSlice.actions;
