import { AxiosInstance } from 'axios';

import { TBank, TBankDto } from '@/types/bank.type';

export class BanksApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async getAdminAllBanks(
    limit: number,
    page: number,
    searchTerm: string
  ): Promise<{
    total: number;
    data: TBank[];
  }> {
    let path = `admin/banks?`;
    if (limit) {
      path += `limit=${limit}`;
    }
    if (page) {
      path += `&page=${page}`;
    }
    if (searchTerm) path += `&searchTerm=${searchTerm}`;
    const res = await this.api.get(path);
    return res.data;
  }

  public async getAdminBankById(bankId: string) {
    return await this.api.get(`admin/banks/${bankId}`);
  }

  public async createAdminBank(data: TBankDto) {
    return await this.api.post(`admin/banks`, data);
  }

  public async updateAdminBank(bankId: string, data: TBankDto) {
    const res = await this.api.patch(`admin/banks/${bankId}`, data);
    return res;
  }
}
