import { PosCredListInterface, PosCreditsServerInterface } from '../type';

export const posCredListAdapter = (
  data: PosCreditsServerInterface['data']
): PosCredListInterface[] => {
  return data.map((item) => ({
    createdDate: item?.create_date || null,
    fullName: `${item.client.first_name} ${item.client.last_name}`,
    loanAmount: item?.loan_amount || null,
    phone: item.client?.phone_number || null,
    product: item?.product?.name || null,
    uuid: item?.uuid,
    status: item?.status || null,
    bankResponse: item?.responses || null,
    dealer: item?.dealer?.name || null,
    userName: `${item?.user?.first_name} ${item?.user?.last_name}`
  }));
};
