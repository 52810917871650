import './styles/Index.scss';

import { ruRU as ru } from '@mui/material/locale';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { pdfjs } from 'react-pdf';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { App } from './App';
import { persistor, store } from './store/index';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme(ru);

// setup worker for react pdf
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

// Setup service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
  });
}

const helmetContext = {};

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider locale={ruRU}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <HelmetProvider context={helmetContext}>
              <Helmet
                titleTemplate="Allur Finance - %s"
                defaultTitle="Allur Finance"
              />

              <App />
            </HelmetProvider>
          </ThemeProvider>
        </BrowserRouter>
      </ConfigProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your admin, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
