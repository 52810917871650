import { AxiosInstance } from 'axios';

import { TAdminModule, TUpdateAdminModule } from '@/types/modulesAdmin';

export class ModulesApi {
  private api: AxiosInstance;
  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async getAdminAllModules(
    limit?: number,
    offset?: number,
    searchTerm?: string
  ): Promise<{
    total: number;
    data: TAdminModule[];
  }> {
    let path = `admin/module_management?`;

    if (limit) {
      path = path + `limit=${limit}`;
    }
    if (offset) {
      path = path + `&offset=${offset}`;
    }
    if (searchTerm) {
      path = path + `&searchTerm=${searchTerm}`;
    }

    const res = await this.api.get(path);

    return res.data;
  }

  public async updateAdminModules(moduleId: string, data: TUpdateAdminModule) {
    const res = await this.api.put(`admin/module_management/${moduleId}`, data);
    return res.data;
  }

  public async createAdminModules(data: TUpdateAdminModule) {
    const res = await this.api.post('admin/module_management', data);
    return res.data;
  }

  public async getAdminModuleById(uuid: string) {
    return await this.api.get(`admin/module_management/${uuid}`);
  }
}
