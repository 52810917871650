import { AxiosInstance } from 'axios';

import {
  TUserDto,
  TUserUpdateDto,
  UpdateUserPasswordInterface
} from '@/types/user.type';

export class UsersApi {
  private api: AxiosInstance;
  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async getAdminAllUsers(
    limit?: number,
    offset?: number,
    searchTerm?: string,
    isActive?: boolean,
    position?: string
  ): Promise<{
    total: number;
    data: TUserDto[];
  }> {
    let path = `admin/users?`;

    if (limit) {
      path = path + `limit=${limit}`;
    }
    if (offset) {
      path = path + `&offset=${offset}`;
    }
    if (searchTerm) {
      path = path + `&searchTerm=${searchTerm}`;
    }

    if (isActive !== undefined) {
      path = path + `&isActive=${isActive}`;
    }
    if (position) {
      path = path + `&position=${position}`;
    }

    const res = await this.api.get(path);

    return res.data;
  }

  public async createAdminUser(data: TUserDto) {
    const newData: UserUpdatePayloadInterface = {
      birthDate: new Date(data.birth_date).toISOString(),
      email: data.email || '',
      firstName: data.first_name || '',
      iin: data.user_iin || '',
      lastName: data.last_name,
      login: data.login,
      middleName: data.middle_name,
      password: data.password,
      phoneNumber: data.phone_number,
      positionId: data.position?.uuid || '',
      salePointId: data.sale_point?.uuid,
      workDateStart: new Date(data.work_date_start).toISOString(),
      dealerIds: data.dealerIds,
      activity_type: data.activity_type,
      freezing_start_date: data.freezing_start_date,
      freezing_end_date: data.freezing_end_date || null
    };
    const res = await this.api.post('admin/users', newData);
    return res.data;
  }

  public async updateAdminUser(
    userId: string,
    data: TUserUpdateDto | Omit<UpdateUserPasswordInterface, 'uuid'>
  ) {
    const res = await this.api.patch(`admin/users/${userId}`, data);
    return res.data;
  }

  public async getAdminUserById(uuid: string) {
    return await this.api.get(`admin/users/${uuid}`);
  }
}

interface UserUpdatePayloadInterface {
  positionId: string;
  salePointId: string | undefined;
  iin: string;
  login: string;
  password: string | undefined;
  workDateStart: string | Date;
  firstName: string;
  lastName: string;
  middleName: string;
  birthDate: string | Date;
  phoneNumber: string;
  email: string;
  dealerIds: string[];
  activity_type: string;
  freezing_start_date?: string;
  freezing_end_date?: string | null;
}
