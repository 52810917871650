import { Select, TablePaginationConfig } from 'antd';
import React, { ReactNode } from 'react';

const { Option: AntOption } = Select;

type SelectComponentProps = {
  value: string | number;
  onChange: (value: string | number) => void;
  children: ReactNode;
};

type OptionProps = {
  value: string | number;
  children: string | ReactNode;
};

const selectComponentClass: (
  pagination: TablePaginationConfig
) => React.FC<SelectComponentProps> & {
  Option: React.FC<OptionProps>;
} = (pagination) => {
  function InnerSelect({ value, children, onChange }: SelectComponentProps) {
    const checkedPagination =
      (pagination && typeof pagination !== 'boolean' && pagination) ||
      undefined;
    const currentAmount = (checkedPagination?.current || 0) * +value;
    const minAmount = currentAmount - +value + 1;
    const maxAmount =
      currentAmount > (checkedPagination?.total || 0)
        ? checkedPagination?.total
        : currentAmount;

    const newChildren = Array.isArray(children)
      ? children?.map((item) => ({
          ...item,
          props: { ...item.props, children: `Показать ${item.props.value}` }
        }))
      : children;

    return (
      <>
        <span className="table__shown-text">
          {minAmount} - {maxAmount} из {pagination?.total}
        </span>

        <Select size="small" value={value} onChange={onChange}>
          {newChildren}
        </Select>
      </>
    );
  }
  InnerSelect.Option = AntOption;

  return InnerSelect;
};

export default selectComponentClass;
