import './header.scss';

import { DownOutlined } from '@ant-design/icons';
import { Avatar, Badge, Dropdown, MenuProps, Space } from 'antd';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/components/Shared/routes/routes';
import BellIcon from '@/media/icons/bell.svg?react';
import BurgerMenuIcon from '@/media/icons/burger-menu.svg?react';
import ChevronDownIcon from '@/media/icons/chevron-down.svg?react';
import { clearLocalStorageHelper } from '@/services/helpers/clearLocalStorage';
import { getUserToken } from '@/services/user-hash';
import { useAppDispatch, useAppSelector } from '@/store';
import { setIsAuth } from '@/store/auth.slice';
import { onToggleShowNotifications } from '@/store/notifications.slice';
import { resetReqSuccessIndicator } from '@/store/offerStep.slice';
import { setSidebarExpanded } from '@/store/userInfo.slice';
import { DecodedJWTToken } from '@/types/user';

import { getPageInfo, shouldShowBackArrow } from './helpers/get-page-info';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { lastNotificationsChecked } = useAppSelector(
    (state) => state.notifications
  );
  const pageTitle = useAppSelector((state) => state.newOrder.pageTitle);
  const dealer = useAppSelector((state) => state.userInfo.dealer);
  const [user, setUser] = useState<{
    lastName: string;
    firstName: string;
    actions: number[];
  }>({
    firstName: '',
    lastName: '',
    actions: []
  });

  const items: MenuProps['items'] = [
    {
      key: 'personal',
      label: <p>Личный кабинет</p>
    },
    ...(user.actions.includes(0)
      ? [
          {
            key: 'adminPage',
            label: <p>Панель администратора</p>
          }
        ]
      : []),
    {
      key: 'logout',
      label: <p>Выйти</p>
    }
  ];

  useEffect(() => {
    const accessToken = getUserToken().accessToken;
    if (accessToken) {
      const data = jwtDecode<DecodedJWTToken>(accessToken || '');
      setUser((prevState) => ({
        ...prevState,
        firstName: data?.firstName || '',
        lastName: data?.lastName || '',
        actions: data?.actions || []
      }));
    }
  }, []);

  const onClickItems: MenuProps['onClick'] = ({ key }) => {
    if (key === 'logout') {
      clearLocalStorageHelper();
      dispatch(setIsAuth(false));
      navigate(ROUTES.login);
    } else {
      navigate(`/${key}`);
    }
  };

  const isHasBackArrow = shouldShowBackArrow(location.pathname);

  return (
    <header className="header">
      <div className="header__container">
        <button
          onClick={() => dispatch(setSidebarExpanded(true))}
          className="header__burger"
        >
          <BurgerMenuIcon />
        </button>

        <div
          className={`header__title-wrapper ${isHasBackArrow ? 'header__title-wrapper--clickable' : ''}`}
          onClick={() => {
            if (!isHasBackArrow) return;
            dispatch(resetReqSuccessIndicator());
            navigate(-1);
          }}
        >
          {isHasBackArrow && <ChevronDownIcon />}

          <div>
            <h2 className="header__title">
              {getPageInfo(location.pathname) || pageTitle}
            </h2>

            {isHasBackArrow && <p className="header__secondary-text">Назад</p>}
            {location.pathname === '/' && (
              <p className="header__secondary-text">г. Алматы</p>
            )}
          </div>
        </div>

        <div className="header__pagination">
          <p className="header__dealer">{dealer?.label}</p>
          <div className="header__language">RU</div>

          <Badge dot={!lastNotificationsChecked} offset={[-10, 4]}>
            <button
              className="header__bell"
              onClick={() => dispatch(onToggleShowNotifications())}
            >
              <BellIcon />
            </button>
          </Badge>

          <Dropdown
            menu={{ items, onClick: onClickItems }}
            placement="bottomRight"
            arrow
          >
            <Space className="header__user">
              <Avatar className="header__user-avatar">
                {user.firstName[0]?.toUpperCase()}
                {user.lastName[0]?.toUpperCase()}
              </Avatar>{' '}
              <p className="header__user-name">
                {user.firstName} {user.lastName}
              </p>
              <DownOutlined />
            </Space>
          </Dropdown>
        </div>
      </div>
    </header>
  );
};

export default Header;
