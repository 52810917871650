import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type TAppStore = {
  wsIsConnected: boolean;
  showSelectCarModal: boolean;
  showBiometricVerificating: boolean;
  showVerifyFirstStep: boolean;
  showVerifySecondStep: boolean;
  verifyModalState: {
    showVerifyModal: {
      prevClient: boolean;
      smsVerf: boolean;
    };
    isNewApplication: boolean;
  };
  verifyModalInsurance: {
    showVerifyModalInsurance: {
      prevClientInsurance: boolean;
      smsVerfInsurance: boolean;
    };
    isNewApplicationInsurance: boolean;
  };
  verifyModalLitro: {
    showVerifyModalLitro: {
      prevClientLitro: boolean;
      smsVerifyLitro: boolean;
    };
  };
};

const initialState: TAppStore = {
  wsIsConnected: false,
  showBiometricVerificating: false,
  showSelectCarModal: false,
  showVerifyFirstStep: false,
  showVerifySecondStep: false,
  verifyModalState: {
    showVerifyModal: {
      prevClient: false,
      smsVerf: false
    },
    isNewApplication: false
  },
  verifyModalInsurance: {
    showVerifyModalInsurance: {
      prevClientInsurance: false,
      smsVerfInsurance: false
    },
    isNewApplicationInsurance: false
  },
  verifyModalLitro: {
    showVerifyModalLitro: {
      prevClientLitro: false,
      smsVerifyLitro: false
    }
  }
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setShowSelectCarModal: (state, action: PayloadAction<boolean>) => {
      state.showSelectCarModal = action.payload;
    },
    setShowVerifyFirstStep: (state, action: PayloadAction<boolean>) => {
      state.showVerifyFirstStep = action.payload;
    },
    setShowVerifySecondStep: (state, action: PayloadAction<boolean>) => {
      state.showVerifySecondStep = action.payload;
    },
    setVerifyModalIsNewApplication: (state, action: PayloadAction<boolean>) => {
      state.verifyModalState.isNewApplication = action.payload;
    },
    setShowBiometricVerificating(state, action: PayloadAction<boolean>) {
      state.showBiometricVerificating = action.payload;
    },
    setWsIsConnected(state, action: PayloadAction<boolean>) {
      state.wsIsConnected = action.payload;
    }
  }
});

export const {
  setShowSelectCarModal,
  setShowVerifyFirstStep,
  setShowVerifySecondStep,
  setVerifyModalIsNewApplication,
  setShowBiometricVerificating,
  setWsIsConnected
} = appSlice.actions;
