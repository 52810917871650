import './date-range-picker-field.scss';

import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { forwardRef, useEffect, useMemo, useState } from 'react';

import CalendarIcon from '@/media/icons/calendar.svg?react';

import MaskedInput, { MaskedInputInterface } from '../masked-input';
import OutsideClickHandler from '../outside-click-handler';

dayjs.extend(customParseFormat);

type ChangedMaskedInputInterface = Omit<
  MaskedInputInterface,
  'value' | 'onChange' | 'mask'
>;

interface IProps extends ChangedMaskedInputInterface {
  value?: (string | undefined)[];
  onChange?: (value: (string | undefined)[]) => void;
  isFrozen?: boolean;
}
const { RangePicker } = DatePicker;

const DateRangePickerField = forwardRef<HTMLInputElement, IProps>(
  ({ value, onChange, isFrozen, ...rest }, forwardRef) => {
    const [focused, setFocused] = useState(false);
    const [inputValue, setInputValue] = useState<string[]>(['', '']);

    const dates = useMemo(() => {
      if (!value) return [null, null];
      const [startDate, endDate] = value;
      return [
        dayjs(startDate).isValid() ? dayjs(startDate) : null,
        dayjs(endDate).isValid() ? dayjs(endDate) : null
      ];
    }, [value]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      const dates = newValue.split(' - ');
      onChange?.(
        dates.map((item) =>
          dayjs(item, 'DD.MM.YYYY').isValid()
            ? dayjs(item, 'DD.MM.YYYY')?.format('YYYY-MM-DD')
            : ''
        )
      );
      setInputValue(dates);
    };

    const handleCalendarChange = (
      dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
    ) => {
      if (!dates) return;
      onChange?.(
        dates?.map((item) => dayjs(item, 'DD.MM.YYYY')?.format('YYYY-MM-DD'))
      );
    };

    useEffect(() => {
      if (value && dayjs(value[0]).isValid() && dayjs(value[1]).isValid()) {
        setInputValue(value.map((item) => dayjs(item).format('DD.MM.YYYY')));
      }
    }, [value]);

    return (
      <OutsideClickHandler
        onOutsideClick={() => setFocused(false)}
        ignoreElement=".date-range-picker-field__calendar-dropdown"
      >
        <div className="date-range-picker-field">
          <MaskedInput
            {...rest}
            type="tel"
            ref={forwardRef}
            value={inputValue[0] ? inputValue.join(' - ') : ''}
            onChange={handleInputChange}
            endContent={
              <span className="date-range-picker-field__icon">
                <CalendarIcon />
              </span>
            }
            onClick={(e) => {
              if (!isFrozen) setFocused((prev) => !prev);
              rest.onClick?.(e);
            }}
            mask="__.__.____ - __.__.____"
            replacement={rest.replacement || { _: /\d/ }}
            autoComplete="off"
            isFrozen={isFrozen}
          />

          <RangePicker
            picker="date"
            value={dates as [Dayjs | null, Dayjs | null]}
            onCalendarChange={handleCalendarChange}
            className="date-range-picker-field__calendar"
            popupClassName="date-range-picker-field__calendar-dropdown"
            format={'DD.MM.YYYY'}
            open={focused}
          />
        </div>
      </OutsideClickHandler>
    );
  }
);

DateRangePickerField.displayName = 'DateRangePickerField';

export default DateRangePickerField;
