import { STATUSES_ENUM } from '@/components/Shared/constants/statuses';

import { PosCredStatusCodeEnum } from '../../PosCredApclication/types/slice-types';

export const BANK_STATUSES = {
  [PosCredStatusCodeEnum.draft]: STATUSES_ENUM.default,
  [PosCredStatusCodeEnum.sms_verificating]: STATUSES_ENUM.processing,
  [PosCredStatusCodeEnum.error]: STATUSES_ENUM.error,
  [PosCredStatusCodeEnum.declined]: STATUSES_ENUM.error,
  [PosCredStatusCodeEnum.mobile_client]: STATUSES_ENUM.processing,
  [PosCredStatusCodeEnum.waiting_bank_response]: STATUSES_ENUM.warning,
  [PosCredStatusCodeEnum.financed]: STATUSES_ENUM.success,
  [PosCredStatusCodeEnum.approved]: STATUSES_ENUM.success,
  [PosCredStatusCodeEnum.refunded]: STATUSES_ENUM.default,
  [PosCredStatusCodeEnum.refund_in_progress]: STATUSES_ENUM.warning
};

export const POS_CREDIT_BANK_POSITIONS: string[] = [
  PosCredStatusCodeEnum.financed,
  PosCredStatusCodeEnum.refunded,
  PosCredStatusCodeEnum.refund_in_progress,
  PosCredStatusCodeEnum.mobile_client,
  PosCredStatusCodeEnum.approved,
  PosCredStatusCodeEnum.waiting_bank_response,
  PosCredStatusCodeEnum.declined,
  PosCredStatusCodeEnum.error,
  PosCredStatusCodeEnum.draft,
  PosCredStatusCodeEnum.sms_verificating
];
