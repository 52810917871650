import { useEffect, useRef, useState } from 'react';

import { AUTOCOMPLETE_GROUP, IAutoCompleteOption } from './autocomplete';

const KEY_CODES = {
  DOWN: 40,
  UP: 38,
  PAGE_DOWN: 34,
  ESCAPE: 27,
  PAGE_UP: 33,
  ENTER: 13
};

interface IProps {
  value: string;
  lastValue: string;
  onChange: (value: string) => void;
  defaultOptions: IAutoCompleteOption[];
  delay: number;
  source: (search: string) => IAutoCompleteOption[];
  handleFocus: (value: string) => void;
  handleBlur: (value: string) => void;
}

const useAutoComplete = ({
  value,
  lastValue,
  onChange,
  defaultOptions,
  delay,
  source,
  handleFocus,
  handleBlur
}: IProps) => {
  const listRef = useRef<HTMLUListElement>(null);

  const [suggestions, setSuggestions] = useState<IAutoCompleteOption[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isBusy, setBusy] = useState(false);
  const selectionArray =
    suggestions.length > 0 ? suggestions : value === '' ? defaultOptions : [];

  const [myTimeout, setMyTimeOut] = useState(setTimeout(() => {}, 0));

  useEffect(() => {
    const selectedItem = selectionArray.findIndex(
      (item) => item.label === lastValue
    );
    if (selectedItem !== -1) {
      setSelectedIndex(selectedItem);
    }
  }, [lastValue]);

  useEffect(() => {
    if (listRef.current && selectedIndex !== -1) {
      listRef.current.scrollTop =
        (listRef.current.children[selectedIndex] as HTMLElement)?.offsetTop -
        listRef.current.children[selectedIndex]?.clientHeight;
    }
  }, [selectedIndex]);

  function delayInvoke(cb: () => void) {
    if (myTimeout) {
      clearTimeout(myTimeout);
    }
    setMyTimeOut(setTimeout(cb, delay));
  }

  function clearSuggestions() {
    setSuggestions([]);
    setSelectedIndex(-1);
  }

  function selectOption(index: number) {
    if (index > -1) {
      onChange(selectionArray[index].label);
    }
    handleBlur(selectionArray[index]?.label);
    clearSuggestions();
  }

  async function getSuggestions(searchTerm: string) {
    if (searchTerm && source) {
      const options = await source(searchTerm);
      handleFocus(searchTerm);
      setSuggestions(options);
    }
  }

  function onTextChange(searchTerm: string) {
    setBusy(true);
    onChange(searchTerm);
    clearSuggestions();
    delayInvoke(() => {
      getSuggestions(searchTerm);
      setBusy(false);
    });
  }

  function scrollUp() {
    if (selectedIndex > 1) {
      const nextElement = selectionArray[selectedIndex - 1];
      const nextIndex = nextElement?.value === AUTOCOMPLETE_GROUP ? 2 : 1;
      setSelectedIndex(selectedIndex - nextIndex);
    } else if (selectedIndex === 1) {
      const nextElement = selectionArray[0];
      const nextIndex =
        nextElement?.value === AUTOCOMPLETE_GROUP
          ? selectionArray.length - 1
          : 0;
      setSelectedIndex(nextIndex);
    } else if (selectedIndex === -1 || selectedIndex === 0) {
      setSelectedIndex(selectionArray.length - 1);
    }
  }

  function scrollDown() {
    if (selectedIndex < selectionArray.length - 1 || selectedIndex === -1) {
      const nextElement = selectionArray[selectedIndex + 1];
      const nextIndex = nextElement?.value === AUTOCOMPLETE_GROUP ? 2 : 1;
      setSelectedIndex(selectedIndex + nextIndex);
    } else if (selectedIndex === selectionArray.length - 1) {
      const nextElement = selectionArray[0];
      const nextIndex = nextElement?.value === AUTOCOMPLETE_GROUP ? 1 : 0;
      setSelectedIndex(nextIndex);
    }
  }

  function pageDown() {
    const nextElement = selectionArray[selectionArray.length - 1];
    const nextIndex =
      nextElement?.value === AUTOCOMPLETE_GROUP
        ? selectionArray.length - 2
        : selectionArray.length - 1;
    setSelectedIndex(nextIndex);
  }

  function pageUp() {
    const nextElement = selectionArray[0];
    const nextIndex = nextElement?.value === AUTOCOMPLETE_GROUP ? 1 : 0;
    setSelectedIndex(nextIndex);
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const keyOperation = {
      [KEY_CODES.DOWN]: scrollDown,
      [KEY_CODES.UP]: scrollUp,
      [KEY_CODES.ENTER]: () => selectOption(selectedIndex),
      [KEY_CODES.ESCAPE]: clearSuggestions,
      [KEY_CODES.PAGE_DOWN]: pageDown,
      [KEY_CODES.PAGE_UP]: pageUp
    };
    if (keyOperation[e.keyCode]) {
      keyOperation[e.keyCode]();
    } else {
      setSelectedIndex(-1);
    }
  }

  return {
    bindOption: {
      onClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        const nodes = Array.from(listRef.current?.children || []);
        // @ts-ignore
        selectOption(nodes.indexOf(e.target.closest('li')));
      }
    },
    bindInput: {
      value,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        onTextChange(e.target.value),
      onKeyDown
    },
    bindOptions: {
      ref: listRef
    },
    isBusy,
    suggestions: selectionArray,
    selectedIndex,
    clearSuggestions
  };
};

export default useAutoComplete;
