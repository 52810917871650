import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { logsApi } from '@/integrations/index.api';
import { TFlashLog } from '@/types/flashLog.type';

type TLogsSlice = {
  flashLogs: TFlashLog[];
  total: number;
  isLoading: boolean;
};

const initialState: TLogsSlice = { flashLogs: [], total: 0, isLoading: false };

export const getFlashLogsByParam = createAsyncThunk(
  'admin/flashLogs/getByParam',
  async ({
    param,
    limit,
    page
  }: {
    param: string;
    limit: number;
    page: number;
  }) => {
    const res = await logsApi.getAdminLogs(param, limit, page);
    return res;
  }
);

export const adminLogsSlice = createSlice({
  name: 'admin/banks',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFlashLogsByParam.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFlashLogsByParam.fulfilled, (state, action) => {
        state.isLoading = false;
        state.flashLogs = action.payload.flash_logs;
        state.total = action.payload.total;
      });
  }
});
