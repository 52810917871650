import { AxiosInstance } from 'axios';

import { TPosition } from '@/types/position.type';

export class PositionsApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async getAdminAllPositions(
    limit?: number,
    page?: number,
    searchTerm?: string,
    isActive?: boolean
  ): Promise<{
    total?: number;
    data: TPosition[];
  }> {
    let path = `admin/positions/getAll?`;

    if (limit) {
      path = path + `limit=${limit}`;
    }
    if (page) {
      path = path + `&page=${page}`;
    }
    if (searchTerm) {
      path = path + `&searchTerm=${searchTerm}`;
    }
    if (isActive !== undefined) {
      path = path + `&isActive=${isActive}`;
    }
    const res = await this.api.get(path);

    return res.data;
  }

  public async createPosition(data: TPosition) {
    const res = await this.api.post('admin/positions/create', data);
    return res.data;
  }

  public async updatePosition(data: TPosition) {
    const res = await this.api.patch(
      `admin/positions/${data.uuid}/update`,
      data
    );
    return res.data;
  }
  public async getPositionById(uuid: string) {
    return await this.api.get(`admin/positions/${uuid}/findById`);
  }
}
