import '@/styles/Insurance.scss';

import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LitroHeader } from '@/components/App/LitroPnd/LitroForm/LitroHeader';
import { LitroSearch } from '@/components/App/LitroPnd/LitroSearch';
import { LitroTable } from '@/components/App/LitroPnd/LitroTable';
import { ROUTES } from '@/components/Shared/routes/routes';
import { useAppDispatch, useAppSelector } from '@/store';
import { setShowVerifySecondStep } from '@/store/app.slice';
import {
  getLitroById,
  setLitroRequest,
  setLitroUuid
} from '@/store/litro.slice';

export const Litro: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { litroResponses, isLoading } = useAppSelector((state) => state.litro);
  const [pagination, setPagination] = useState<{ limit: number; page: number }>(
    {
      limit: 10,
      page: 1
    }
  );

  const handleGetLitroById = async (uuid: string) => {
    dispatch(setLitroUuid(uuid));
    const resultAction = await dispatch(getLitroById(uuid));
    dispatch(setLitroRequest(resultAction.payload));
    const updatedLitroRequest = resultAction.payload; // Assuming the payload contains the litroRequest data

    if (
      updatedLitroRequest &&
      updatedLitroRequest.status_code === 'sms_verificating'
    ) {
      dispatch(setShowVerifySecondStep(true));
    } else {
      navigate(ROUTES.litroForm);
    }
  };

  return (
    <div className="litro">
      <LitroHeader />
      <LitroSearch pagination={pagination} />
      <LitroTable
        litroReq={litroResponses}
        isLoading={isLoading}
        getItemById={(value) => {
          handleGetLitroById(value);
        }}
        pagination={pagination}
        setPagination={(value) => setPagination(value)}
      />
    </div>
  );
};
