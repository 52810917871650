import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DictTypesKeys } from '@/constants';
import { orderAppMapper } from '@/services/mappers/orderAppMapper';
import { tsModalInfoMapper } from '@/services/mappers/tsModalInfoMapper';
import { TModalTSValues } from '@/types/financing';

import { apiConnector } from '../integrations/api.connector';
import { TNewOrder, TNewOrderDto } from '../types/newOrder';
import { TDict, TNewOrderStore } from '../types/store';
import { getResponseByRequestId } from './offerStep.slice';

export const createNewOrder = createAsyncThunk(
  'newOrder/createNewOrder',
  async (
    reqDto: {
      data: TNewOrderDto;
      dicts: Record<DictTypesKeys, TDict>;
    },
    { dispatch }
  ): Promise<any> => {
    try {
      const response = await apiConnector.postOrder(reqDto.data);

      if (response.id) {
        await dispatch(
          getRequestById({
            reqId: response?.inputs?.req_body.request_id,
            dicts: reqDto.dicts
          })
        );
        await dispatch(
          getResponseByRequestId(
            response?.inputs?.req_body.request_id.toString()
          )
        );
        localStorage.removeItem(reqDto?.data?.uuid);
        return response;
      } else {
        throw new Error();
      }
    } catch (e: unknown) {
      throw new Error(e as string | undefined);
    }
  }
);

export const getRequestById = createAsyncThunk(
  'newOrder/gerRequestById',
  async (dataObject: {
    reqId: string;
    dicts: Record<DictTypesKeys, TDict>;
  }): Promise<any> => {
    try {
      const data = await apiConnector.getRequestById(dataObject.reqId);
      return {
        data: data,
        dicts: dataObject.dicts
      };
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

const initialState: TNewOrderStore = {
  isError: false,
  orderApp: null as TNewOrder | null,
  tsModalValues: {
    requestUid: '',
    licensePlate: '',
    vin: '',
    bodyNum: '',
    paymentDate: '',
    registerCompany: '',
    engineCapacity: '',
    carColor: '',
    registerDate: '',
    registerNum: ''
  },
  pageTitle: '',
  isLoading: false,
  isCreateLoading: false,
  clientInfo: null as {
    iin: string;
    fullName: string[];
    telNum: string;
    id: string;
  } | null,
  requestId: '',
  isNewOrderError: false,
  fieldsEditable: false,
  current: 0,
  loan: {
    amount: '',
    auto: ''
  }
};

export const newOrderSlice = createSlice({
  name: 'newOrder',
  initialState,
  reducers: {
    setOrderApp: (state, action: PayloadAction<TNewOrder>) => {
      state.orderApp = action.payload;
    },
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload;
    },
    setClientInfo: (state, action) => {
      state.clientInfo = action.payload;
    },
    setIsNewOrderError: (state, action: PayloadAction<boolean>) => {
      state.isNewOrderError = action.payload;
    },
    setCurrent: (state, action: PayloadAction<number>) => {
      state.current = action.payload;
    },
    setRequestData: (state, action: PayloadAction<string>) => {
      state.requestId = action.payload;
    },
    setLoanData: (
      state,
      action: PayloadAction<{ amount: string; auto: string }>
    ) => {
      state.loan = action.payload;
    },
    setNewOrderToInitial: (state) => {
      state.orderApp = null;
    },
    resetIsError: (state) => {
      state.isError = false;
    },
    setTsModalValues: (state, action: PayloadAction<TModalTSValues>) => {
      state.tsModalValues = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewOrder.pending, (state) => {
        state.isLoading = true;
        state.isCreateLoading = true;
        state.isError = false;
      })
      .addCase(createNewOrder.rejected, (state) => {
        state.isLoading = false;
        state.isNewOrderError = false;
        state.isCreateLoading = false;
        state.isError = true;
      })
      .addCase(createNewOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isNewOrderError = false;
        state.isCreateLoading = false;
        state.isError = false;
        state.requestId = action.payload.inputs.req_body.request_id;
      })

      .addCase(getRequestById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRequestById.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(
        getRequestById.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.orderApp = orderAppMapper(
            action.payload.data,
            action.payload.dicts
          );
          state.requestId = action.payload.data.uuid;
          state.tsModalValues = tsModalInfoMapper(action.payload.data);
        }
      );
  }
});

export const {
  setOrderApp,
  setPageTitle,
  setClientInfo,
  setCurrent,
  setRequestData,
  setLoanData,
  setNewOrderToInitial,
  resetIsError,
  setTsModalValues
} = newOrderSlice.actions;
