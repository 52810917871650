import { removeUserToken } from '../user-hash';

export const clearLocalStorageHelper = () => {
  removeUserToken();
  localStorage.removeItem('userSearchType');
  localStorage.removeItem('isRemember');
  localStorage.removeItem('paginationInfo');
  localStorage.removeItem('dealer');
  localStorage.removeItem('isAuthenticated');
};
