import { AxiosInstance } from 'axios';

import { ServerPosCredApplicationInterface } from '@/components/App/PosCred/PosCredApclication/types/slice-types';
import { PosCreditsServerInterface } from '@/components/App/PosCred/PosCreds/type';

export class PosCredApi {
  private api: AxiosInstance;
  private baseUrl = '/pos-credit';

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public getPosCredList(params: string) {
    return this.api.get<PosCreditsServerInterface>(`${this.baseUrl}${params}`);
  }

  public getPosCredById(uuid: string) {
    return this.api.get<ServerPosCredApplicationInterface>(
      `${this.baseUrl}/${uuid}`
    );
  }

  public retryOtp(requestId: string) {
    return this.api.post(`${this.baseUrl}/${requestId}/otp/retry`);
  }

  public generateDraftWithOtp(data: GenerateOtpData & { dealerId: string }) {
    return this.api.post<{ request_uuid: string }>(
      `${this.baseUrl}/generateDraftWithOtp`,
      data
    );
  }

  public validatePosCredOtp(data: ValidateOtp) {
    return this.api.post(`${this.baseUrl}/validateOTP`, data);
  }

  public updatePosCred({ request_uuid, body }: UpdatePosCredRequestInterface) {
    return this.api.patch(`${this.baseUrl}/${request_uuid}`, body);
  }

  public acceptOffer(offer_id: string) {
    return this.api.post(`${this.baseUrl}/offers/${offer_id}/accept`);
  }

  public refundOffer(request_uuid: string, offer_id: string) {
    return this.api.post(`${this.baseUrl}/offers/${offer_id}/refund`);
  }
}

export interface UpdatePosCredRequestInterface {
  request_uuid: string;
  body: {
    client_iin: string;
    dealer_code: number;
    loan_amount: number;
    loan_term: number | null;
    phone_number: string;
    product_bank_id: string;
    product_dc: string;
    product_code: string;
    uuid: string;
  };
  setStep: () => void;
}

interface GenerateOtpData {
  iin: string;
  clientPhone: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
}

interface ValidateOtp {
  otp: string;
  request_uuid: string;
}
