import {
  BankOutlined,
  CarOutlined,
  FundProjectionScreenOutlined,
  IdcardOutlined,
  LogoutOutlined,
  ProfileOutlined,
  ProjectOutlined,
  QuestionCircleOutlined,
  ShopOutlined,
  TagsOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import {
  ADMIN_ROUTES,
  ROUTES,
  getFullAdminRoute
} from '@/components/Shared/routes/routes';
import IconMessage from '@/media/icons/message.svg?react';
import IconModules from '@/media/svg/Filter.svg?react';
import IconLeasing from '@/media/svg/percent.svg?react';

type TAdminNavBarProps = {
  isSidebarExpanded: boolean;
};

const adminSideBarItems = [
  {
    label: 'На главную',
    key: ROUTES.autoCredit,
    icon: <LogoutOutlined style={{ fontSize: '20px' }} />
  },
  {
    label: 'Дилерские центры',
    key: getFullAdminRoute(ADMIN_ROUTES.dealers),
    icon: <ShopOutlined style={{ fontSize: '20px' }} />
  },
  {
    label: 'Банки',
    key: getFullAdminRoute(ADMIN_ROUTES.banks),
    icon: <BankOutlined style={{ fontSize: '20px' }} />
  },
  {
    label: 'Клиенты',
    key: getFullAdminRoute(ADMIN_ROUTES.clients),
    icon: <IdcardOutlined style={{ fontSize: '20px' }} />
  },
  {
    label: 'Акции/Субсидии',
    key: getFullAdminRoute(ADMIN_ROUTES.promotion),
    icon: <TagsOutlined style={{ fontSize: '20px' }} />
  },
  {
    label: 'Лизинг',
    key: getFullAdminRoute(ADMIN_ROUTES.leasings),
    icon: <IconLeasing />
  },
  {
    label: 'Продукты',
    key: getFullAdminRoute(ADMIN_ROUTES.products),
    icon: <ProjectOutlined style={{ fontSize: '20px' }} />
  },
  {
    label: 'Авто',
    key: getFullAdminRoute(ADMIN_ROUTES.auto),
    icon: <CarOutlined style={{ fontSize: '20px' }} />
  },
  {
    label: 'Пользователи',
    key: getFullAdminRoute(ADMIN_ROUTES.users),
    icon: <UserOutlined style={{ fontSize: '20px' }} />
  },
  {
    label: 'Модули',
    key: getFullAdminRoute(ADMIN_ROUTES.modules),
    icon: <IconModules style={{ fontSize: '20px' }} />
  },
  {
    label: 'Логи',
    key: getFullAdminRoute(ADMIN_ROUTES.logs),
    icon: <ProfileOutlined style={{ fontSize: '20px' }} />
  },
  {
    label: 'Отчеты',
    key: getFullAdminRoute(ADMIN_ROUTES.reports),
    icon: <FundProjectionScreenOutlined />
  },
  {
    label: 'Поддержка',
    key: getFullAdminRoute(ADMIN_ROUTES.faq),
    icon: <QuestionCircleOutlined />
  },
  {
    label: 'Системные уведомления',
    key: getFullAdminRoute(ADMIN_ROUTES.systemNotifications),
    icon: <IconMessage />
  }
];

export const AdminNavBar: FC<TAdminNavBarProps> = ({ isSidebarExpanded }) => {
  const location = useLocation();

  return (
    <Menu
      mode="inline"
      theme="dark"
      inlineCollapsed={!isSidebarExpanded}
      className="admin-navbar"
      style={{
        marginLeft: isSidebarExpanded ? '-16px' : '0px',
        width: !isSidebarExpanded ? '50px' : '230px'
      }}
    >
      {Array.isArray(adminSideBarItems) &&
        adminSideBarItems.map((item) => {
          const isActive = location.pathname.includes(item.key);

          return (
            <Menu.Item
              key={item.key}
              icon={item.icon}
              className={`admin-navbar__menu-item ${isActive ? 'ant-menu-item-selected navbar__link--active' : ''}`}
            >
              <NavLink
                to={item.key}
                className={`navbar__link ${isActive ? 'navbar__link--active' : ''}`}
              >
                {item.label}
              </NavLink>
            </Menu.Item>
          );
        })}
    </Menu>
  );
};
