import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { clientsApi } from '@/integrations/index.api';
import { clientMapperAdmin } from '@/services/mappers/clientResponseMapper';
import { TClientData, TClientServerResponse } from '@/types/clients';

type TClientsSliceProps = {
  clients: TClientServerResponse[] | null;
  client: TClientData | null;
  total: number;
  isLoading: boolean;
};

const initialState: TClientsSliceProps = {
  clients: null,
  client: null,
  total: 0,
  isLoading: false
};

export const getAdminAllClients = createAsyncThunk(
  'admin/clients/getAll',
  async (data: { limit?: number; page?: number; searchTerm?: string }) => {
    try {
      const res = await clientsApi.getAdminAllClients(
        data.limit,
        data.page,
        data.searchTerm
      );
      return {
        total: res.total,
        clients: res.data
      };
    } catch {
      throw new Error('Failed to fetch users');
    }
  }
);

export const getAdminClientByIin = createAsyncThunk(
  'admin/clients/:id',
  async (id: string) => {
    const res = await clientsApi.getAdminClientById(id);

    return res;
  }
);

export const updateClientById = createAsyncThunk(
  'admin/clients',
  async (payload: TClientData) => {
    const newData: TClientData = {
      ...payload
    };
    if (!newData.birth_date) delete newData.birth_date;
    if (!newData.company_activity_type_uuid)
      delete newData.company_activity_type_uuid;
    if (!newData.marital_status_uuid) delete newData.marital_status_uuid;
    if (!newData.gender_uuid) delete newData.gender_uuid;
    if (!newData.social_status_uuid) delete newData.social_status_uuid;
    if (!newData.organization_type_uuid) delete newData.organization_type_uuid;
    const res = await clientsApi.updateClientById(newData);

    return res;
  }
);

export const adminClientsSlice = createSlice({
  name: 'admin/clients',
  initialState,
  reducers: {
    resetClientState(state) {
      state.client = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminAllClients.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdminAllClients.fulfilled, (state, action) => {
        state.clients = action.payload.clients;
        state.total = action.payload.total;
        state.isLoading = false;
      })
      .addCase(getAdminAllClients.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(updateClientById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateClientById.fulfilled, (state, action) => {
        state.client = action.payload;
        state.isLoading = false;
      })
      .addCase(updateClientById.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAdminClientByIin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdminClientByIin.fulfilled, (state, action) => {
        const parsedData = clientMapperAdmin(action.payload);
        state.client = parsedData;
        state.isLoading = false;
      })
      .addCase(getAdminClientByIin.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const { resetClientState } = adminClientsSlice.actions;
