import { TBankOffers } from '@/types/offers';

import { formatBackBankOfferResponse } from '../formatter/formatBackBankOfferResponse';

export const bankResponsesMapper = (bankResponses: TBankOffers[]) => {
  const formattedBankResponses = bankResponses.map((response) => {
    return formatBackBankOfferResponse(response);
  });

  return formattedBankResponses;
};
