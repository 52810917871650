import { TModalTSValues } from '@/types/financing';
import { TRequest } from '@/types/request';

export const tsModalInfoMapper = (request: TRequest): TModalTSValues => {
  // Код электрического двигателя - 4
  const isElectricVehicle = request?.engine_type?.code === 4;

  return {
    requestUid: request?.uuid || '',
    vin: request?.vin_code || '',
    registerDate: request?.vehicle_registration_date || '',
    registerNum: request?.vehicle_registration_number?.toString() || '',
    paymentDate: request?.vehicle_first_payment_date || '',
    licensePlate: request?.vehicle_plate_number || '',
    carColor: request?.final_color_text || '',
    bodyNum: request?.vehicle_body_number?.toString() || '',
    engineCapacity: isElectricVehicle
      ? '0'
      : request?.vehicle_engine_capacity?.toString() || '',
    registerCompany:
      request?.vehicle_registration_company?.code?.toString() || ''
  };
};
