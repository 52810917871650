import './circled-image.scss';

import { useState } from 'react';

interface IProps {
  src: string;
  alt: string;
  size?: number;
}

const CircledImage: React.FC<IProps> = ({ src, alt, size = 32 }) => {
  const [isError, setIsError] = useState(false);

  return (
    <div
      style={{
        // @ts-ignore
        '--size': `${size}px`
      }}
      className={`circled-image${isError ? ' circled-image--error' : ''}`}
    >
      {isError ? (
        <span className="circled-image__text">{alt[0].toUpperCase()}</span>
      ) : (
        <img src={src} alt={alt} onError={() => setIsError(true)} />
      )}
    </div>
  );
};

export default CircledImage;
