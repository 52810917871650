import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  ClientFormValues,
  OtpFormValues
} from '@/components/Shared/entities/client-otp-modal';
import { posCredApi } from '@/integrations/index.api';

import { posCredListAdapter } from './adapter/posCredListAdapter';
import { InitialStateInterface } from './type';

const initialState: InitialStateInterface = {
  posCredList: {
    data: [],
    total: 0,
    status: 'idle',
    error: null
  },
  generateOtp: {
    error: null,
    status: 'idle'
  },
  validateOtp: {
    data: null,
    status: 'idle',
    error: null
  }
};

export const fetchPosCredList = createAsyncThunk(
  'posCred/fetchPosCredList',
  async ({ queryParams }: { queryParams: string }, { rejectWithValue }) => {
    try {
      const response = await posCredApi.getPosCredList(queryParams);

      const newDate = posCredListAdapter(response.data.data);

      return { data: newDate, total: response.data.total };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const generateDraftWithOtp = createAsyncThunk(
  'posCred/generateDraftWithOtp',
  async ({
    fullName,
    iin,
    phone,
    dealerId
  }: ClientFormValues & { dealerId: string }) => {
    const [lastName, firstName, middleName] = fullName.split(' ');

    const res = await posCredApi.generateDraftWithOtp({
      clientPhone: phone,
      firstName,
      lastName,
      middleName,
      iin,
      dealerId
    });

    return res.data;
  }
);

export const validatePosCredOtp = createAsyncThunk(
  'posCred/validatePosCredOtp',
  async ({
    first,
    fourth,
    second,
    third,
    uuid
  }: { uuid: string } & OtpFormValues) => {
    const res = await posCredApi.validatePosCredOtp({
      request_uuid: uuid,
      otp: first + second + third + fourth
    });

    return res.data;
  }
);

export const retryPosCredOtp = createAsyncThunk(
  'posCred/retryOtp',
  async ({ requestId }: { requestId: string }) => {
    const res = await posCredApi.retryOtp(requestId);

    return res.data;
  }
);

export const posCredSlice = createSlice({
  name: 'posCred',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosCredList.pending, (state) => {
        state.posCredList.status = 'pending';
        state.posCredList.error = null;
      })
      .addCase(fetchPosCredList.fulfilled, (state, action) => {
        state.posCredList.status = 'fulfilled';
        state.posCredList.data = action.payload?.data || [];
        state.posCredList.total = action.payload?.total || 1;
      })
      .addCase(fetchPosCredList.rejected, (state, action) => {
        state.posCredList.status = 'rejected';
        state.posCredList.error = {
          message: action.error.message || 'Unknown error',
          code: action.error.code || 'Unknown error'
        };
      })
      .addCase(generateDraftWithOtp.pending, (state) => {
        state.generateOtp.status = 'pending';
        state.generateOtp.error = null;
      })
      .addCase(generateDraftWithOtp.rejected, (state, action) => {
        state.generateOtp.error = {
          message: action.error.message || 'Unknown error',
          code: action.error.code || 'Unknown error'
        };
        state.generateOtp.status = 'rejected';
      })
      .addCase(generateDraftWithOtp.fulfilled, (state) => {
        state.generateOtp.status = 'fulfilled';
        state.generateOtp.error = null;
      })
      .addCase(validatePosCredOtp.pending, (state) => {
        state.validateOtp.status = 'pending';
        state.validateOtp.error = null;
      })
      .addCase(validatePosCredOtp.rejected, (state, action) => {
        state.validateOtp.status = 'rejected';
        state.validateOtp.error = {
          message: action.error.message || 'Unknown error',
          code: action.error.code || 'Unknown error'
        };
      })
      .addCase(validatePosCredOtp.fulfilled, (state) => {
        state.validateOtp.status = 'fulfilled';
        state.validateOtp.error = null;
      });
  }
});
