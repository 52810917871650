import { AxiosInstance } from 'axios';

import { TDealer, TDealerDto } from '@/types/dealer.type';

export class DealersApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public async getAdminAllDealers(
    limit?: number,
    page?: number,
    searchTerm?: string,
    city?: string,
    isActive?: boolean,
    hasCommercial?: boolean
  ): Promise<{
    total: number;
    dealers: TDealer[];
  }> {
    let path = `admin/dealers?`;
    if (limit) {
      path += `limit=${limit}`;
    }
    if (page) {
      path += `&page=${page}`;
    }
    if (searchTerm) {
      path += `&searchTerm=${searchTerm}`;
    }
    if (city) {
      path += `&city=${city}`;
    }
    if (isActive !== undefined) {
      path += `&isActive=${isActive}`;
    }
    if (hasCommercial !== undefined) {
      path += `&hasCommercial=${hasCommercial}`;
    }
    const res = await this.api.get(path);
    return res.data;
  }

  public async getAdminDealerByParam(
    param: string | number
  ): Promise<TDealer[]> {
    return await this.api.get(`admin/dealers/${param}/findByParam`);
  }

  public async createAdminDealer(dealer: TDealerDto) {
    return await this.api.post(`admin/dealers`, dealer);
  }

  public async updateAdminDealer(dealer: TDealer) {
    return await this.api.patch(`admin/dealers/${dealer.uuid}`, dealer);
  }

  public async getAdminById(uuid: string) {
    return await this.api.get(`admin/dealers/${uuid}`);
  }
}
