import './text-editor.scss';

import JoditEditor, { IJoditEditorProps } from 'jodit-react';
import { useEffect, useMemo } from 'react';

type TTextEditorProps = {
  defaultHeight?: number;
  basicHTML: string;
  onChange: (data: any) => void;
  props?: IJoditEditorProps;
  isIframe?: boolean;
  removeButtons?: string[];
};

const TextEditor = ({
  defaultHeight = 600,
  basicHTML,
  onChange,
  props,
  isIframe = true,
  removeButtons
}: TTextEditorProps) => {
  const config = useMemo(
    () => ({
      readonly: false,
      tabIndex: 1,
      iframe: isIframe,
      height: defaultHeight,
      uploader: {
        insertImageAsBase64URI: true
      },
      filebrowser: {
        ajax: {
          url: null // Отключает серверный файловый менеджер
        }
      },
      removeButtons
    }),
    []
  );

  useEffect(() => {
    const frame = document.querySelector<HTMLIFrameElement>(
      '#jodit-editor iframe'
    );
    if (!frame || !frame.contentWindow) return;
    const frameDocument = frame.contentWindow.document;

    const tables = frameDocument.querySelectorAll('table');
    tables.forEach((element: HTMLElement) => {
      element.style.marginLeft = '0';
    });
  }, []);

  return (
    <div id="jodit-editor">
      <JoditEditor
        {...props}
        value={basicHTML}
        config={config}
        onChange={onChange}
      />
    </div>
  );
};

export default TextEditor;
