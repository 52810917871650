import { useCallback, useEffect, useState } from 'react';

import { debounce } from '@/services/helpers/debounce';

interface IProps {
  focused: boolean;
  setFocused: React.Dispatch<React.SetStateAction<boolean>>;
  listElement: HTMLUListElement | null;
  isFrozen?: boolean;
}

const usePopup = ({ focused, setFocused, listElement, isFrozen }: IProps) => {
  const [topPosition, setTopPosition] = useState(false);

  const handleScroll = useCallback(
    debounce(() => {
      // Disable setting position when popup opened
      if (focused) return;

      if (listElement) {
        const listTopPosition = listElement.getBoundingClientRect().top;
        if (window.innerHeight - 260 < listTopPosition) {
          setTopPosition(true);
        } else {
          setTopPosition(false);
        }
      }
    }, 300),
    [focused]
  );

  useEffect(() => {
    handleScroll();
    const main = document.querySelector('main');
    main?.addEventListener('scroll', handleScroll);
    return () => {
      main?.removeEventListener('scroll', handleScroll);
    };
  }, [listElement, handleScroll]);

  const handlePopupToggle = () => !isFrozen && setFocused((prev) => !prev);

  return { topPosition, handlePopupToggle };
};

export default usePopup;
