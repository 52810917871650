import './chosen-bank-status.scss';

import { statusColors } from '@/constants';

import CircledImage from '../circled-image';

interface IProps {
  img: string;
  name: string;
  status: {
    code: string;
    name?: string;
  };
  size?: 'big';
}

const ChosenBankStatus: React.FC<IProps> = ({ img, name, status, size }) => {
  const colors =
    statusColors[status.code as keyof typeof statusColors] ??
    statusColors.draft;

  return (
    <div
      className={`chosen-bank-status ${size ? `chosen-bank-status--${size}` : ''}`}
    >
      <CircledImage src={img} alt={name} />

      <div>
        <p className="chosen-bank-status__name">{name}</p>
        {status.code === 'chosen' ? (
          <p className="chosen-bank-status__description">Банк выбран</p>
        ) : (
          <div
            style={{
              color: colors.textColor,
              backgroundColor: colors.bgColor
            }}
            className={`chosen-bank-status__mark`}
          >
            {status.name}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChosenBankStatus;
