import { saveAs } from 'file-saver';

/**
 * Конвертирует объект Blob в строку base64.
 *
 * @param {Blob} blob - Объект Blob, который нужно конвертировать.
 * @returns {Promise<string>} - Промис, который разрешается в строку base64.
 */
export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
};

/**
 * Конвертирует строку base64 в объект Blob.
 *
 * @param {string} base64 - Строка в формате base64.
 * @param {string} [mimeType='application/octet-stream'] - MIME-тип для создаваемого Blob.
 * @returns {Blob} - Объект Blob, созданный из строки base64.
 */
export const base64ToBlob = (
  base64: string,
  mimeType: string = 'application/octet-stream'
): Blob => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
};

/**
 * Конвертирует буфер в строку base64.
 *
 * @param {any} buffer - Буфер, который нужно конвертировать.
 * @returns {string} - Строка в формате base64, созданная из буфера.
 */
export const bufferToBase64 = (buffer: ArrayBuffer): string => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;

  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return window.btoa(binary);
};

/**
 * Конвертирует файл в строку base64.
 *
 * @param {File} file - Файл, который нужно конвертировать.
 * @returns {Promise<string>} - Промис, который разрешается в строку base64.
 */
export const convertFileToBase64 = async (file: File): Promise<string[]> => {
  try {
    const base64String = await new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
    return base64String.split(',');
  } catch {
    return ['', ''];
  }
};

/**
 * Сохраняет строку base64 как PDF-файл.
 *
 * @param {string} base64String - Строка в формате base64, представляющая PDF.
 * @param {string} fileName - Имя файла для сохранения.
 */
export const saveBase64AsPDF = (base64String: string, fileName: string) => {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const pdfBlob = new Blob([byteArray], { type: 'application/pdf' });

  saveAs(pdfBlob, fileName);
};
/**
 * Функция `calculateFileSizeInMB` вычисляет размер файла в мегабайтах (MB), исходя из его размера в байтах.
 *
 * @param sizeInBytes - Размер файла в байтах, который требуется преобразовать в мегабайты.
 * @returns Строка, представляющая размер файла в мегабайтах с округлением до двух знаков после запятой.
 *
 * @example
 * ```typescript
 * const fileSize = calculateFileSizeInMB(10485760); // "10.00"
 * ```
 */
export const calculateFileSizeInMB = (sizeInBytes: number): string => {
  const sizeInMB = sizeInBytes / (1024 * 1024);
  return sizeInMB.toFixed(2);
};

/**
 * Функция addExtensionIfMissing проверяет, содержит ли название файла расширение.
 * Если расширение отсутствует, функция добавляет его из ссылки на файл и возвращает обновленное имя.
 *
 * @param {Object} file - Объект с информацией о файле.
 * @param {string} file.name - Название файла без расширения или с ним.
 * @param {string} file.url - Ссылка на файл, содержащая расширение.
 *
 * @returns {string} - Название файла с расширением, если оно отсутствовало, или оригинальное название.
 */
export const addExtensionIfMissing = (file: {
  name: string;
  url: string;
}): string => {
  // Регулярное выражение для получения расширения из ссылки (после последней точки)
  const urlExtensionMatch = file.url.match(/\.(\w+)(?:\?|#|$)/);
  const urlExtension = urlExtensionMatch ? urlExtensionMatch[1] : null;

  // Проверка, есть ли расширение в названии файла
  const hasExtension = /\.\w+$/.test(file.name);

  // Если расширение в названии отсутствует и оно найдено в ссылке, добавляем его
  if (!hasExtension && urlExtension) {
    return `${file.name}.${urlExtension}`;
  }

  return file.name;
};
