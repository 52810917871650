import { Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Modal } from 'antd';
import { FC, useEffect, useState } from 'react';

import { CustomInput } from '@/components/Shared/Input/CustomInput';
import { CustomPhoneInput } from '@/components/Shared/Input/CustomPhoneInput';
import { DictTypes } from '@/constants';
import { isValidInput, validateNumberInputValue } from '@/services';
import { cleanPhoneNumber, formatPhoneNumber } from '@/services/formatter';
import { checkVerifyInputValuesValid } from '@/services/validators/checkVerifyInputValuesValid';
import { useAppDispatch, useAppSelector } from '@/store';
import { setShowVerifyFirstStep } from '@/store/app.slice';
import { TClientInfoOtpDto } from '@/types/otp.type';

type TFirstStepProps = {
  fioInputShow: boolean;
  sendClient: (client: TClientInfoOtpDto) => void;
  isLoading: boolean;
  clientInfoItem?: TClientInfoOtpDto;
  sendIin?: (value: string) => void;
};

export const FirstStepVerify: FC<TFirstStepProps> = ({
  fioInputShow,
  sendClient,
  isLoading,
  clientInfoItem,
  sendIin
}) => {
  const dispatch = useAppDispatch();
  const { dealer } = useAppSelector((state) => state.userInfo);
  const { dicts } = useAppSelector((state) => state.dict);
  const { showVerifyFirstStep } = useAppSelector((state) => state.app);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [clientInfo, setClientInfo] = useState<{
    iin: string;
    telNum: string;
    fullName: string;
    city_code?: string | number;
  }>({
    iin: '',
    telNum: '',
    fullName: '',
    city_code: ''
  });

  useEffect(() => {
    if (clientInfoItem) {
      setClientInfo(() => ({
        iin: clientInfoItem.iin,
        telNum: clientInfoItem.clientPhone,
        fullName: `${clientInfoItem.lastName} ${clientInfoItem.firstName} ${clientInfoItem.middleName}`
      }));
    }
  }, []);

  useEffect(() => {
    if (clientInfoItem) {
      setClientInfo((prevState) => ({
        telNum: clientInfoItem.clientPhone,
        fullName: `${clientInfoItem.lastName} ${clientInfoItem.firstName} ${clientInfoItem.middleName}`,
        iin: prevState.iin
      }));
    }
  }, [clientInfoItem]);

  useEffect(() => {
    const isInputValuesValid = checkVerifyInputValuesValid(clientInfo);
    setIsValid(!isInputValuesValid);
  }, [clientInfo]);

  const handleInputChange = (value: string) => {
    if (isValidInput(value)) {
      setClientInfo((prevState) => ({
        ...prevState,
        fullName: value
      }));
    }
  };
  const onOk = () => {
    const inputFullName = clientInfo.fullName
      ?.split(' ')
      .filter((data) => data.trim() !== '');

    const findedDealer = dicts?.[DictTypes.Dealers].content.find(
      (_dealer) => _dealer.uuid === dealer!.value
    );
    const findedCity = dicts?.[DictTypes.Cities].content.find(
      (city) => city.uuid === findedDealer?.city_uuid
    );
    const data: TClientInfoOtpDto = {
      iin: clientInfo.iin,
      clientPhone: clientInfo.telNum,
      firstName: inputFullName ? inputFullName[1] : '',
      lastName: inputFullName ? inputFullName[0] : '',
      middleName: inputFullName ? inputFullName[2] : '',
      dealer_uuid: dealer?.value || '',
      city_code: findedCity?.code
    };
    sendClient({ ...data, clientPhone: cleanPhoneNumber(data.clientPhone) });
  };

  return (
    <Modal
      className={'otp-modal'}
      centered={true}
      cancelButtonProps={{
        className: 'cancel-btn'
      }}
      cancelText="Отмена"
      okText="Далее"
      okButtonProps={{
        disabled: isValid,
        className: 'continue-btn'
      }}
      open={showVerifyFirstStep}
      onCancel={() => {
        dispatch(setShowVerifyFirstStep(false));
      }}
      onOk={onOk}
    >
      {isLoading ? (
        <>
          <Stack spacing={1} justifyContent="center" alignItems="center">
            <CircularProgress />
            <Typography
              fontWeight="400"
              fontSize="14px"
              color="#687588"
              height="20px"
              align="center"
            >
              Подождите...
            </Typography>
          </Stack>
        </>
      ) : (
        <div className="container">
          <p
            style={{
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '140%',
              color: '#111827',
              textAlign: 'center',
              margin: '16px 0 0'
            }}
          >
            Заполните данные о клиенте
          </p>

          <CustomInput
            value={validateNumberInputValue(clientInfo.iin, 12)}
            label={'iin'}
            type={'iin'}
            labelTitle={'ИИН клиента'}
            onChangeInputHandler={(value) => {
              sendIin && sendIin(value);
              setClientInfo((prevState) => ({
                ...prevState,
                iin: value.slice(0, 12)
              }));
            }}
            disabled={!clientInfoItem}
          />
          {fioInputShow && (
            <CustomInput
              value={clientInfo.fullName}
              label={'fullName'}
              type={'fullName'}
              labelTitle={'ФИО клиента'}
              onChangeInputHandler={handleInputChange}
              disabled={!!clientInfoItem?.firstName}
            />
          )}
          <CustomPhoneInput
            disabled={!!clientInfoItem?.firstName}
            value={clientInfo.telNum}
            label={'telNum'}
            type={'telNum'}
            onChangeInputHandler={(value) =>
              setClientInfo((prevState) => ({
                ...prevState,
                telNum: formatPhoneNumber(value)
              }))
            }
          />
        </div>
      )}
    </Modal>
  );
};
