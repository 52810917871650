import { Modal, Select } from 'antd';
import { FC, useState } from 'react';

import { DictTypes } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/store';
import { setDealer } from '@/store/userInfo.slice';

interface IPropSelectDealer {
  show: () => void;
}
const SelectDealerModal: FC<IPropSelectDealer> = ({ show }) => {
  const { userInfo } = useAppSelector((state) => state.userInfo);
  const { dicts } = useAppSelector((state) => state.dict);

  const dealers = dicts?.[DictTypes.Dealers]?.content;

  const dispatch = useAppDispatch();
  const [dealercode, setDealercode] = useState<string | null>(null);

  const handleOk = () => {
    if (dealercode) {
      localStorage.setItem('dealer', dealercode.toString());
      const dealerFind =
        dealers &&
        dealers.find((_dealer) => String(_dealer.code) === dealercode);
      const selectedDealer:
        | null
        | {
            value: string;
            label: string;
            code: number;
          }
        | undefined = dealerFind && {
        value: dealerFind?.uuid,
        label: dealerFind?.name,
        code: dealerFind?.code
      };
      if (selectedDealer) {
        dispatch(setDealer(selectedDealer));
      }
    }
    show();
  };

  const handleChangeSelectDealer = (value: string) => {
    setDealercode(value);
  };

  return (
    <Modal
      centered
      title={<p style={{ fontSize: '18px' }}>Выберите дилерский центр</p>}
      open={true}
      closable={false}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{
        style: {
          fontSize: '16px',
          fontWeight: '600',
          lineHeight: '150%',
          color: '#fff',
          height: '56px',
          width: '182px',
          padding: '16px 24px',
          borderRadius: '10px',
          border: 'none',
          backgroundColor: '#ff2e1f'
        },
        disabled: !dealercode
      }}
      onOk={handleOk}
    >
      <Select
        showSearch
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
        }
        style={{
          height: '40px',
          border: '1px solid #E9EAEC',
          borderRadius: '10px',
          width: '100%',
          marginBottom: '20px'
        }}
        options={
          userInfo?.Users_Dealers && userInfo.Users_Dealers.length > 0
            ? userInfo.Users_Dealers.map((dealer) => ({
                value: String(dealer.dealer.code),
                label: dealer.dealer.name
              }))
            : dealers
              ? dealers.map((dealer) => ({
                  value: String(dealer.code),
                  label: dealer.name
                }))
              : []
        }
        onChange={handleChangeSelectDealer}
      />
    </Modal>
  );
};

export default SelectDealerModal;
