import './Layout.scss';

import { jwtDecode } from 'jwt-decode';
import { FC, Suspense, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

import SelectDealerModal from '@/components/App/SelectDealer/SelectDealerModal';
import { DictTypes } from '@/constants';
import useResize from '@/hooks/useResize';
import { getUserToken } from '@/services/user-hash';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  getOptimizedDictionaries,
  getProductInsurance,
  getProductsDictionary
} from '@/store/dict.slice';
import {
  getUserMenu,
  setDealer,
  setJwtDecodedInfo
} from '@/store/userInfo.slice';
import { DecodedJWTToken } from '@/types/user';

import { AllurLoader } from '../Shared/ui';
import NotificationsSideBar from './Notifications/NotificationsSideBar';
import ErrorTemplate from './errors/error-template';
import { Header, Navbar } from './widgets';

export const Layout: FC = () => {
  const { dicts } = useAppSelector((state) => state.dict);

  const dealers = dicts?.[DictTypes.Dealers]?.content;

  const { dealer, menu } = useAppSelector((state) => state.userInfo);
  const { isUserAuth } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [showSelectDealerModal, setSelectDealerModal] = useState<boolean>(true);

  const [wrapperWidth] = useResize({ element: '.layout .layout__wrapper' });

  const dealerCode = localStorage.getItem('dealer');

  useEffect(() => {
    if (dealerCode) {
      const dealerFind = dealers?.find(
        (_dealer) => String(_dealer.code) === dealerCode
      );

      const selectedDealer = dealerFind && {
        value: dealerFind.uuid,
        label: dealerFind.name,
        code: dealerFind.code
      };

      setSelectDealerModal(false);

      if (selectedDealer) {
        dispatch(setDealer(selectedDealer));
      }
    }
  }, [dealers, dealerCode]);

  useEffect(() => {
    if (isUserAuth && !dealerCode) {
      setSelectDealerModal(true);
    }
  }, [isUserAuth, dealerCode]);

  useEffect(() => {
    if (dealer?.value) dispatch(getUserMenu(dealer.value));
  }, [dispatch, dealer?.value]);

  useEffect(() => {
    const accessToken = getUserToken().accessToken;
    if (accessToken) {
      const data = jwtDecode<DecodedJWTToken>(accessToken || '');
      dispatch(setJwtDecodedInfo(data));
      if (!dicts) dispatch(getOptimizedDictionaries());
      dispatch(getProductsDictionary());
      dispatch(getProductInsurance());
    }
  }, []);

  const showSelectDealerModalToggle = () => {
    setSelectDealerModal(!showSelectDealerModal);
  };

  return (
    <div className="layout">
      <Navbar />

      <div
        className="layout__wrapper"
        style={{
          // @ts-ignore
          '--wrapper-width': `${wrapperWidth}px`
        }}
      >
        <Header />

        <main className="layout__main">
          <ErrorBoundary fallback={<ErrorTemplate type="oops" />}>
            <Suspense fallback={<AllurLoader fullScreen={true} />}>
              {!menu.length ? <AllurLoader fullScreen={true} /> : <Outlet />}
            </Suspense>
          </ErrorBoundary>
        </main>
      </div>

      <NotificationsSideBar />

      {isUserAuth && showSelectDealerModal && (
        <SelectDealerModal show={showSelectDealerModalToggle} />
      )}
    </div>
  );
};
