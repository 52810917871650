import '@/styles/InsuranceStepOrder.scss';

import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ClientInfoFormHeader } from '@/components/App/LitroPnd/LitroForm/ClientInfoFormHeader';
import { CustomInput } from '@/components/Shared/Input/CustomInput';
import { CustomPhoneInput } from '@/components/Shared/Input/CustomPhoneInput';
import { SelectCar } from '@/components/Shared/Modal/SelectCar';
import { CustomSelect } from '@/components/Shared/Select/CustomSelect';
import { ROUTES } from '@/components/Shared/routes/routes';
import { DictTypes } from '@/constants';
import { litroItems } from '@/data/litro.data';
import { setDictionaryOptions } from '@/services';
import { formatPhoneNumber } from '@/services/formatter';
import { litroMapper } from '@/services/mappers/litroMapper';
import { validateLitroInfo } from '@/services/validators/litroValidator';
import { useAppDispatch, useAppSelector } from '@/store';
import { setShowSelectCarModal } from '@/store/app.slice';
import { getPaymentType, updateLitro } from '@/store/litro.slice';
import { TCreateLitroDto } from '@/types/litro.type';

export const LitroForm: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { dicts } = useAppSelector((state) => state.dict);
  const { dealer } = useAppSelector((state) => state.userInfo);
  const { litroRequest, paymentType } = useAppSelector((state) => state.litro);

  const [stateButton, setStateButton] = useState<boolean>(true);
  const [selectedCar, setSelectedCar] = useState<{
    brand: string;
    model: string;
    year: string;
  }>({
    brand: '',
    model: '',
    year: ''
  });
  const [paymentOption, setPaymentOption] = useState<
    { value: string | number; label: string }[]
  >([]);
  const [cityOption, setCityOption] = useState<
    { value: string | number; label: string; uuid: string }[]
  >([]);
  const [data, setData] = useState<TCreateLitroDto>({
    brand_uuid: '',
    car_registration: null,
    car_year: '',
    card_name: '',
    city_code: 'Allur finance',
    client_uuid: '',
    dealer_code: '',
    model_uuid: '',
    payment_type_code: '',
    period_code: null,
    uuid: '',
    vin: ''
  });

  useEffect(() => {
    setStateButton(validateLitroInfo(data));
  }, [data]);

  useEffect(() => {
    dispatch(getPaymentType());

    if (litroRequest) {
      const mappedData = litroMapper(litroRequest);
      setData(mappedData);
      setSelectedCar({
        brand: mappedData.brand_uuid,
        model: mappedData.model_uuid,
        year: mappedData.car_year
      });
    }

    const cities = dicts?.[DictTypes.Cities].content
      .filter((city) => city.is_city)
      .map((c) => ({ value: c.code, label: c.name, uuid: c.uuid }));
    setCityOption(cities ?? []);

    if (dealer) setData((prev) => ({ ...prev, dealer_code: dealer.code }));
  }, [dicts, dealer, litroRequest]);

  useEffect(() => {
    setPaymentOption(
      paymentType.map((item) => ({ value: item.code, label: item.name }))
    );
  }, [paymentType]);

  useEffect(() => {
    const selectedDealer: any = dicts?.Dealers.content.find(
      (d) => d.uuid === dealer?.value
    );
    if (selectedDealer) {
      const city = cityOption.find((c) => c.uuid === selectedDealer.city_uuid);
      setData((prev) => ({
        ...prev,
        // brand_uuid: selectedDealer.sold_brands || '',
        city_code: city?.value || ''
      }));
    }

    if (!litroRequest!.brand_uuid) {
      setData((prev) => ({
        ...prev,
        brand_uuid: selectedDealer.sold_brands || ''
        // city_code: city?.value || ''
      }));
    }
  }, [cityOption, dealer, dicts]);

  useEffect(() => {
    const brand = dicts?.[DictTypes.Brands].content.find(
      (b) => b.uuid === data.brand_uuid
    );
    const model = dicts?.[DictTypes.Models].content.find(
      (m) => m.uuid === data.model_uuid
    );
    setSelectedCar({
      brand: brand?.name || '',
      model: model?.name || '',
      year: litroRequest?.car_year || ''
    });
  }, [data.brand_uuid, data.model_uuid]);

  const handleInputChange = (key: keyof TCreateLitroDto, value: string) => {
    if (key === 'vin')
      value = value
        .replace(/[^A-HJ-NPR-Z0-9]/gi, '')
        .slice(0, 17)
        .toUpperCase();
    if (key === 'car_registration') value = formatCarRegistration(value);

    setData((prevData) => ({ ...prevData, [key]: value }));
  };

  const formatCarRegistration = (value: string) => {
    value = value.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
    return value.length > 0
      ? `${value.slice(0, 3).replace(/[^0-9]/g, '')}${value
          .slice(3, 6)
          .replace(/[^A-Za-z]/g, '')}${value
          .slice(6, 8)
          .replace(/[^0-9]/g, '')}`
      : value;
  };

  const setShowModal = () => {
    dispatch(setShowSelectCarModal(true));
  };

  const renderField = (item: any) => {
    switch (item.type) {
      case 'input':
        return (
          <CustomInput
            key={item.key}
            value={data[item.key as keyof TCreateLitroDto] as string}
            label={item.label}
            type="text"
            labelTitle={item.label}
            onChangeInputHandler={(value) =>
              handleInputChange(item.key as keyof TCreateLitroDto, value)
            }
          />
        );
      case 'select':
        return (
          <CustomSelect
            key={item.key}
            editable={
              !item.editable ||
              litroRequest?.status_code === 'waiting_of_financing' ||
              litroRequest?.status_code === 'financed'
            }
            value={
              item.key === 'markModelYear'
                ? `${selectedCar.brand}, ${selectedCar.model}, ${data.car_year}`
                : (data[item.key as keyof TCreateLitroDto] as string)
            }
            option={
              item.key === 'city_code'
                ? cityOption
                : item.key === 'payment_type_code'
                  ? paymentOption
                  : setDictionaryOptions(
                      item.snakeCaseValues as DictTypes,
                      dicts
                    )
            }
            label={item.key}
            type="modal"
            labelTitle={item.label}
            onChangeSelectHandler={(value) =>
              setData((prev) => ({ ...prev, [item.key]: value }))
            }
            setShowModal={item.editable ? setShowModal : undefined}
          />
        );
      case 'phoneNumber':
        return (
          <CustomPhoneInput
            key={item.key}
            value={
              data[item.key as keyof TCreateLitroDto]
                ? formatPhoneNumber(
                    data[item.key as keyof TCreateLitroDto] as string
                  )
                : ''
            }
            label={item.key}
            onChangeInputHandler={(value) =>
              setData((prev) => ({
                ...prev,
                [item.key as keyof TCreateLitroDto]: formatPhoneNumber(value)
              }))
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <ClientInfoFormHeader
        clientData={{
          firstName: litroRequest?.client?.first_name || '',
          lastName: litroRequest?.client?.last_name || '',
          middleName: litroRequest?.client?.middle_name || '',
          iin: litroRequest?.client?.client_iin || '',
          telNum: litroRequest?.client
            ? formatPhoneNumber(litroRequest.client.phone_number)
            : ''
        }}
      />
      <div className="litro-container-form">
        <div className="product_info">
          <div className="auto_selects">
            <SelectCar
              setSelectedCar={(car) =>
                setData((prev) => ({
                  ...prev,
                  brand_uuid: car.brand,
                  model_uuid: car.model,
                  car_year: car.year
                }))
              }
            />
            <h2>Помощь на дороге</h2>
            <div className="selects_box">
              {litroItems.map((item) => renderField(item))}
            </div>
            <div style={{ marginTop: '20px' }}>
              <button
                disabled={
                  stateButton ||
                  litroRequest?.status_code === 'waiting_of_financing' ||
                  litroRequest?.status_code === 'financed'
                }
                className="continue_btn"
                onClick={() => {
                  dispatch(
                    updateLitro({
                      ...data,
                      period_code: Number(data.period_code),
                      payment_type_code: Number(data.payment_type_code),
                      card_name: 'Allur Card'
                    })
                  ).then(() => navigate(ROUTES.litro));
                }}
              >
                Создать заявку
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
