import React, { useEffect, useRef } from 'react';

const useOutsideClickHandler = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  onOutsideClick: () => void,
  ignoreElement?: string
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const ignore = ignoreElement
        ? document.querySelector(ignoreElement)
        : null;
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !ignore?.contains(event.target as Node)
      ) {
        onOutsideClick();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onOutsideClick]);
};

interface IProps {
  children: JSX.Element;
  onOutsideClick: () => void;
  ignoreElement?: string;
}

const OutsideClickHandler = ({
  children,
  onOutsideClick,
  ignoreElement
}: IProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideClickHandler(wrapperRef, onOutsideClick, ignoreElement);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideClickHandler;
