import { TUserInfo, TUserResponse } from '@/types/user';

export const userInfoMapper = (userResponse: TUserResponse): TUserInfo => {
  const userInfo: TUserInfo = {
    uuid: userResponse?.uuid || '',
    birth_date: userResponse?.birth_date.toString() || new Date().toISOString(),
    dealer: userResponse?.sale_point?.dealer?.name || '',
    email: userResponse?.email || '',
    first_name: userResponse?.first_name || '',
    middle_name: userResponse?.middle_name || '',
    last_name: userResponse?.last_name || '',
    is_active: userResponse?.is_active || false,
    login: userResponse?.login || '',
    position: {
      uuid: userResponse?.position?.uuid || '',
      code: userResponse?.position?.code || 0,
      name: userResponse?.position?.name || '',
      is_active: userResponse?.position?.is_active || false
    },
    sale_point: userResponse?.sale_point?.name || '',
    user_iin: userResponse?.user_iin || '',
    phone_number: userResponse?.phone_number || '',
    work_date_start:
      userResponse?.work_date_start instanceof Date
        ? userResponse.work_date_start.toISOString()
        : new Date().toISOString(),
    activity_type: userResponse?.activity_type || '',
    freezing_end_date: userResponse?.freezing_end_date || '',
    freezing_start_date: userResponse?.freezing_start_date || '',
    Users_Dealers: userResponse?.Users_Dealers || []
  };

  return userInfo;
};
