import { BaseResponseInterface } from '@/types/statuses';

export interface InitialStateInterface {
  posCredUser: BaseResponseInterface & {
    data: PosCredUserApplicationInterface | null;
  };
  setPosCredState: BaseResponseInterface;
  acceptOfferState: BaseResponseInterface;
  refundOfferState: BaseResponseInterface;
}

interface ProductBankInterface {
  uuid: string;
  code: string;
  name: string;
  period: number;
}

interface ProductInterface {
  uuid: string;
  code: string;
  name: string;
  product_type_uuid: string;
}

export interface PosCredUserApplicationInterface {
  uuid: string;
  status: {
    name: string;
    code: PosCredStatusCode;
    uuid: string;
  };
  iin: string;
  fullName: string;
  orderCode: string;
  phoneNumber: string;
  productDc: string | null;
  productBankUuid: string | null;
  product: ProductInterface | null;
  productBank: ProductBankInterface | null;
  loanAmount: string;
  loanTerm: number;
  bankResponse: BankResponseInterface[] | null;
}

export interface ServerPosCredApplicationInterface {
  uuid: string;
  client_uuid: string;
  user_uuid: string;
  status_uuid: string;
  create_date: string;
  update_date: string | null;
  product_uuid: string | null;
  otp: string;
  enter_token: string;
  loan_amount: string | null;
  loan_term: number | null;
  dealer_uuid: string | null;
  code: string;
  product_dc: string | null;
  product_bank_uuid: string | null;
  product: ProductInterface | null;
  product_bank: ProductBankInterface | null;
  status: { uuid: string; name: string; code: PosCredStatusCode };
  client: {
    uuid: string;
    client_iin: string;
    dissuing_authority_uuid: string | null;
    document_number: string | null;
    first_name: string;
    middle_name: string;
    last_name: string;
    phone_number: string;
    email: string | null;
    birth_date: string | null;
    birth_place: string | null;
    expired_document_date: string | null;
    document_type_uuid: string | null;
    registration_date: string | null;
    salary: string | null;
    additional_income: string | null;
    gender_uuid: string | null;
    country_uuid: string | null;
    resident_status_uuid: string | null;
    marital_status_uuid: string | null;
    job_title: string | null;
    child_count: string | null;
    social_status_uuid: string | null;
    organization_type_uuid: string | null;
    is_ipdl: string | null;
    is_live_registered: string | null;
    education_type_uuid: string | null;
    property_type_uuid: string | null;
    company_activity_type_uuid: string | null;
    last_work_experience: string | null;
    total_work_experience: string | null;
    title_company: string | null;
    work_address: string | null;
    work_tel_number: string | null;
    create_date: string;
    create_user: string;
  };
  responses: BankResponseInterface[] | null;
}

export interface BankResponseInterface {
  bank_response_uuid?: string;
  uuid: string;
  reason: string;
  loan_period?: any;
  loan_amount: any;
  monthly_payment: any;
  interest_rate: any;
  effective_rate: any;
  ext_uuid: string;
  is_accepted: boolean;
  product: string;
  product_type?: string;
  productDc: string;
  created_at: string;
  status: {
    uuid: string;
    name: string;
    code: PosCredStatusCode;
  };
  bank: {
    name: 'ffb' | 'forte';
    fullname: string;
    code: string;
    uuid: string;
  };
  dealer: {
    name: string;
  };
  offers: {
    uuid: string;
    monthly_payment: number;
    loan_rate: string | null;
    loan_period: number;
    loan_amount: number;
    product: string;
    product_type: string;
    is_accepted: boolean;
  }[];
}

export const enum PosCredStatusCodeEnum {
  sms_verificating = 'sms_verificating',
  draft = 'draft',
  waiting_bank_response = 'waiting_bank_response',
  error = 'error',
  declined = 'declined',
  mobile_client = 'mobile_client',
  financed = 'financed',
  approved = 'approved',
  refunded = 'refunded',
  refund_in_progress = 'refund_in_progress'
}

export type PosCredStatusCode = keyof typeof PosCredStatusCodeEnum;
