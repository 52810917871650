import {
  ADMIN_ROUTES,
  ROUTES,
  getFullAdminRoute
} from '@/components/Shared/routes/routes';

export const INNER_PAGES = [
  ROUTES.faqTemplate(),
  ROUTES.autoCreditCreate,
  ROUTES.autoCreditRequest(),
  ROUTES.calculator,
  ROUTES.insurancesCreate,
  ROUTES.insurancesRequest(),
  ROUTES.leasingCreate,
  ROUTES.leasingRequest(),
  ROUTES.posCreditCreate(),
  ROUTES.posCreditRequest(),
  ROUTES.client(),

  getFullAdminRoute(ADMIN_ROUTES.client()),
  getFullAdminRoute(ADMIN_ROUTES.dealersCreate),
  getFullAdminRoute(ADMIN_ROUTES.dealer()),
  getFullAdminRoute(ADMIN_ROUTES.usersCreate),
  getFullAdminRoute(ADMIN_ROUTES.user()),
  getFullAdminRoute(ADMIN_ROUTES.positionCreate),
  getFullAdminRoute(ADMIN_ROUTES.product()),
  getFullAdminRoute(ADMIN_ROUTES.productsCreate),
  getFullAdminRoute(ADMIN_ROUTES.position()),
  getFullAdminRoute(ADMIN_ROUTES.rolesActionCreate),
  getFullAdminRoute(ADMIN_ROUTES.rolesAction()),
  getFullAdminRoute(ADMIN_ROUTES.modulesCreate),
  getFullAdminRoute(ADMIN_ROUTES.module()),
  getFullAdminRoute(ADMIN_ROUTES.leasingCreate),
  getFullAdminRoute(ADMIN_ROUTES.leasing()),
  getFullAdminRoute(ADMIN_ROUTES.brandsCreate),
  getFullAdminRoute(ADMIN_ROUTES.brand()),
  getFullAdminRoute(ADMIN_ROUTES.modelsCreate),
  getFullAdminRoute(ADMIN_ROUTES.model()),
  getFullAdminRoute(ADMIN_ROUTES.faqCreate),
  getFullAdminRoute(ADMIN_ROUTES.faqInfo()),
  getFullAdminRoute(ADMIN_ROUTES.equipmentsCreate),
  getFullAdminRoute(ADMIN_ROUTES.equipment()),
  getFullAdminRoute(ADMIN_ROUTES.productsCreate),
  getFullAdminRoute(ADMIN_ROUTES.banksCreate),
  getFullAdminRoute(ADMIN_ROUTES.bank()),
  getFullAdminRoute(ADMIN_ROUTES.systemNotificationsCreate),
  getFullAdminRoute(ADMIN_ROUTES.systemNotification())
];
