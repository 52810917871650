export enum STATUS_COLORS_ENUM {
  success = '#0CAF60',
  warning = '#E6BB20',
  error = '#E03137',
  default = '#111827',
  processing = 'rgb(2, 61, 61)'
}

export enum STATUSES_ENUM {
  success = 'success',
  warning = 'warning',
  error = 'error',
  default = 'default',
  processing = 'processing'
}
