import './style.scss';

import { checkIcon } from '@/constants';

interface IProps {
  activeTab: string;
  setActiveTab: (key: string) => void;
  tabs: { title: string; key: string }[];
  alwaysChecked?: number[];
  lastChecked?: boolean;
  finishedText?: string;
}

const CustomTab = ({
  activeTab,
  setActiveTab,
  tabs,
  alwaysChecked,
  finishedText
}: IProps) => {
  const activeIndex = tabs.findIndex((item) => item.key === activeTab) || 0;

  return (
    <div className="ui-custom-tab-wrapper">
      <div className="ui-custom-tab">
        {tabs.map((tab, index) => {
          const isTabFinished = index < activeIndex;
          const isAlwaysChecked = alwaysChecked?.includes(index);
          const currentText =
            (isAlwaysChecked && index === tabs.length - 1 && finishedText) ||
            'Заполнен';

          return (
            <div
              key={tab.key}
              className={`ui-custom-tab__tab-item ${
                activeTab === tab.key ? 'ui-custom-tab__tab-item--active' : ''
              } ${isTabFinished ? 'ui-custom-tab__tab-item--done' : ''}`}
              onClick={() => setActiveTab(tab.key)}
            >
              <div>
                {activeTab === tab.key && !isAlwaysChecked && (
                  <p className="ui-custom-tab__status">Текущий</p>
                )}
                {(isTabFinished || isAlwaysChecked) && (
                  <p className="ui-custom-tab__status ui-custom-tab__status--done">
                    {currentText}
                  </p>
                )}
                <div className="ui-custom-tab__title-block">
                  <span className="ui-custom-tab__number">{index + 1}</span>
                  <span className="ui-custom-tab__title">{tab.title}</span>
                </div>
              </div>
              {(isTabFinished || isAlwaysChecked) && (
                <img className="ui-custom-tab__done-icon" src={checkIcon} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomTab;
